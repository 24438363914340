exports.install = function (Vue) {
  Vue.prototype.ContinuousReply = function (ruleForm, list, rounds, bloodVolume, monsterList) {

    // 将传入的对象的所有键值存在数组中方便后面有需求时使用
    let arr = Object.keys(ruleForm)
    // 把对象中的基础常用数据解构出来
    let { Lowest, Supreme, Attack, frequency, automatic, loop, occupation } = ruleForm
    //最低最高武器伤害位置纠正
    if (Lowest > Supreme) {
      let room = Lowest
      Lowest = Supreme
      Supreme = room
    }

    // 总攻次 = 被动 + 主动
    Attack += frequency
    // 攻次最高99
    Attack = Attack > 99 ? 99 : Attack

    // 副本回合估算数据
    let copy = []
    loop = 30
    // 怪物强命血量和总血量
    let bloodVolumeforcedHit = bloodVolume

    // 怪物当前回血
    let recovery = 0
    // 怪物总回血
    let recoverySum = 0
    // 强命怪物回血
    let recoveryforcedHit = 0
    // 怪物强命总回血
    let recoveryforcedHitSum = 0

    // 伤害计算
    for (let i = 1; i <= loop; i++) {
      // 副本回合估算攻次是按回合叠加
      if (i <= 5 && automatic === "魔化武器") {
        Attack += 6
      }

      if (arr.includes("round") && automatic === "混开魔化") {
        if (i <= 5 && i % 2 !== 0) {
          Attack += 6
        }
      }
      // 攻次最高99
      Attack = Attack > 99 ? 99 : Attack

      // 随机生成格挡---block为格挡
      let block = 0
      // 物理攻击才会有格挡
      if (occupation === '物理攻击') {
        // 优化格挡计算---格挡最高为7
        // let block_random = 0.7
        // for (let i = 0; i < Attack; i++) {
        //   if (block_random >= 0.1 && Math.random() <= block_random) {
        //     block++
        //     block_random -= 0.1
        //   }
        // }

        // 格挡最高为7
        if (Attack < 7) {
          block = Math.round(Math.random() * Attack)
        } else {
          block = Math.round(Math.random() * 7)
        }
      }
      //实际攻次
      let actualattacktimes = Attack - block

      // 武伤均值计算---生成武伤的均值用于数据计算
      let generateMean = 0
      for (let i = 0; i < Attack; i++) {
        // 动态生成武伤
        let room = Math.round(Math.random() * (Supreme - Lowest) + Lowest)
        generateMean += room
      }

      // 平均武伤 = 武伤总值 / 循环次数
      generateMean = parseInt(generateMean / Attack)

      if (rounds <= i) {
        if (occupation === '物理攻击') {
          if (bloodVolumeforcedHit === '武伤浮动强命伤害已完成任务') break
        } else {
          if (bloodVolume === '武伤浮动伤害已完成任务') break
        }
        // 当前回合攻击力
        let data = list[i - 1].datas
        // 当前回合的伤害----攻击力考虑格挡的影响
        let hurts = parseInt((generateMean + parseInt(data / Attack)) * actualattacktimes * 1.5 * 0.3)
        hurts = attackLimit(hurts, actualattacktimes)
        // 当前回合实际伤害
        let hurt = 0
        // 怪物血量剩余==>怪物还有血量并且不为物理攻击,因为物理攻击还要考虑闪避问题
        if (bloodVolume && (occupation !== '物理攻击')) {
          if (typeof bloodVolume === 'number') {
            // 怪物的回血
            recovery = parseInt(bloodVolume * 0.005)
            bloodVolume += recovery
            recoverySum += recovery
          }
          if (bloodVolume > hurts) {
            bloodVolume -= hurts
            hurt = hurts
          } else {
            hurt = bloodVolume
            recovery = '武伤浮动伤害已完成任务'
            bloodVolume = '武伤浮动伤害已完成任务'
          }
        }
        if (occupation === '物理攻击') {
          // 当前回合命中
          let hit = list[i - 1].hits
          // 当前回合怪物闪避
          let currentDodge = monsterList[i - 1].dataObjectList
          // 物理攻击伤害经过一次伤害转义==>命中率=当前命中/当前怪物闪避
          // 命中率
          let hitRate = 0
          // 如果怪物没有闪避则命中率为100%
          if (currentDodge === 0) {
            hitRate = 100
          } else {
            hitRate = (hit - currentDodge)
          }
          // 判断命中率是否不可计算,如果不可计算则为0
          if (isNaN(hitRate)) {
            hitRate = 0
          }
          // 命中率最低为0,最高为100
          hitRate = hitRate < 0 ? 0 : hitRate
          hitRate = hitRate > 100 ? 100 : hitRate
          // 当前回合强命伤害
          let { forcedHit } = ruleForm
          forcedHit /= 100
          // 强命伤害
          let hurtforcedHit = parseInt(hurts * forcedHit)
          // 检测命中和闪避的关系
          if (hitRate < (forcedHit * 100)) {
            // 角色当前命中率小于强制命中率则使用强制命中率
            hurts = hurtforcedHit
          } else {
            // 角色当前命中率大于强制命中率则考虑命中率是否为100%
            hurts = parseInt(hurts * (hitRate / 100).toFixed(2))
          }
          // 怪物血量剩余
          if (bloodVolume) {
            if (typeof bloodVolume === 'number') {
              // 怪物的回血
              recovery = parseInt(bloodVolume * 0.005)
              bloodVolume += recovery
              recoverySum += recovery
            }
            if (bloodVolume > hurts) {
              bloodVolume -= hurts
              hurt = hurts
            } else {
              hurt = bloodVolume
              recovery = '武伤浮动伤害已完成任务'
              bloodVolume = '武伤浮动伤害已完成任务'
            }
          }
          // 怪物强命血量剩余
          if (bloodVolumeforcedHit) {
            if (typeof bloodVolumeforcedHit === 'number') {
              // 怪物的回血
              recoveryforcedHit = parseInt(bloodVolumeforcedHit * 0.005)
              bloodVolumeforcedHit += recoveryforcedHit
              recoveryforcedHitSum += recoveryforcedHit
            }
            if (bloodVolumeforcedHit > hurtforcedHit) {
              bloodVolumeforcedHit -= hurtforcedHit
            } else {
              hurtforcedHit = bloodVolumeforcedHit
              recoveryforcedHit = '武伤浮动强命伤害已完成任务'
              bloodVolumeforcedHit = '武伤浮动强命伤害已完成任务'
            }
          }
          copy.push({
            // 当前回合
            round: `第【${i}】回合`,
            // 强命值为
            forcedHit: `强制命中率:${parseInt(forcedHit * 100)}%`,
            // 当前回合平均武器伤害
            generateMean: `当前回合平均武器伤害:${generateMean}`,
            // 当前回合攻次为
            actualattacktimes: `当前回合攻次为:${actualattacktimes}`,
            // 当前回合攻击力
            data: `当前回合攻击力:${data}`,
            // 当前回合命中率
            hitRate: `当前回合命中率:${hitRate}%`,
            // 怪物当前回合所回复的血量
            recovery: `当前回血:${recovery}`,
            // 怪物当前回合强命所回复的血量
            recoveryforcedHit: `当前强命回血:${recoveryforcedHit}`,
            // 当前回合伤害
            hurt: `当前回合伤害:${hurt}`,
            // 当前怪物所剩血量
            bloodVolume: `当前回合怪物所剩血量:${bloodVolume}`,
            // 当前回合强命伤害
            hurtforcedHit: `当前回合强命伤害:${hurtforcedHit}`,
            // 强命当前怪物所剩血量
            bloodVolumeforcedHit: `当前回合强命攻击怪物所剩血量:${bloodVolumeforcedHit}`,
            // 当前总回血
            recoverySum: `当前总的回血量:${recoverySum}`,
            // 当前强命总回血
            recoveryforcedHitSum: `当前强命总的回血量:${recoveryforcedHitSum}`
          })
        } else {
          copy.push({
            // 当前回合
            round: `第【${i}】回合`,
            // 当前回合平均武器伤害
            generateMean: `当前回合平均武器伤害:${generateMean}`,
            // 当前回合攻次为
            actualattacktimes: `当前回合攻次为:${actualattacktimes}`,
            // 当前回合攻击力
            data: `当前回合攻击力:${data}`,
            // 怪物当前回合所回复的血量
            recovery: `当前回血:${recovery}`,
            // 当前回合伤害
            hurt: `当前回合伤害:${hurt}`,
            // 当前怪物所剩血量
            bloodVolume: `当前回合怪物所剩血量:${bloodVolume}`,
            // 当前总回血
            recoverySum: `当前总的回血量:${recoverySum}`,
          })
        }
      }
    }
    // 吧数据暴露出去
    return copy
  }
  // 单攻次攻击力上限
  function attackLimit(aggressivity, Attack) {
    // 最终伤害在±5%之间浮动--由worldH5作者、晨风提出，由worldH5作者、晨风、冰蝶、最帅汤布丽布验证验证
    aggressivity = parseInt((Math.random() * (105 - 95 + 1) + 95) / 100 * aggressivity)
    // 单攻次伤害上限418758
    aggressivity = (aggressivity / Attack) < 418758 ? (aggressivity / Attack) : 418758
    return parseInt(aggressivity * Attack)
  }
}