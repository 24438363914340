<template>
  <div id="app">
    <!-- 可以被缓存的视图组件 -->
    <transition name='fade' mode="out-in">
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive"></router-view>
      </keep-alive>
    </transition>

    <!-- 不需要被缓存的试图组件 -->
    <!-- transition 表示过渡动画效果 -->
    <transition name="fade" mode="out-in">
      <!-- 路由出口，所有组件都展示到这里 -->
      <router-view v-if="!$route.meta.keepAlive"></router-view>
    </transition>

    <!-- 移动端 -->
    <div v-if="this.$equipment()">
      <!-- 控制底部导航是否展示 -->
      <van-tabbar route placeholder z-index="9999" v-if="$route.meta.TabbarShow">
        <van-tabbar-item icon="wap-home-o" to="/android-home">首页</van-tabbar-item>
        <van-tabbar-item icon="cluster-o" to="/android-WebsiteTools">网站工具</van-tabbar-item>
        <van-tabbar-item to="/android-WebsiteInformation">
          <van-icon class="iconfont" class-prefix="icon" name="xitong"></van-icon>
          网站信息
        </van-tabbar-item>
      </van-tabbar>
    </div>
  </div>
</template>

<script>
// 定义一个构造函数,用于处理并发问题
class SuperTask {
  constructor(parallelCount = 2) {
    // 并发数量
    this.parallelCount = parallelCount
    // 需要执行的任务
    this.tasks = []
    // 正在执行的任务数量
    this.runningCount = 0
  }

  // 添加任务
  add(task) {
    return new Promise((resolve, reject) => {
      this.tasks.push({
        task,
        resolve,
        reject
      })
      this._run()
    })
  }

  // 执行任务
  _run() {
    while (this.runningCount < this.parallelCount && this.tasks.length > 0) {
      const { task, resolve, reject } = this.tasks.shift()
      task.then(_ => {
        resolve(true)
      }, reject).finally(() => {
        this.runningCount--
        this._run()
      })
    }
  }
}

// 手写改造PromisrAll方法
const promiseAll = (promiseArr) => {
  return new Promise((resolve, reject) => {
    const superTask = new SuperTask()
    if (!Array.isArray(promiseArr)) {
      throw new Error("参数只能为数组")
    }

    // 完成的promise 数量
    let count = 0
    for (let i = 0, len = promiseArr.length; i < len; i++) {
      superTask.add(promiseArr[i]).then(value => {
        if (value) {
          count++
        }

        if (count === len) {
          console.log("请告诉她，她很美，我很喜欢她")
          resolve()
        }
      }).catch(err => reject(err))
    }
  })
}

// 从vuex中获取数据
import { mapMutations } from 'vuex'
export default {
  name: 'MyApp',
  // 方法区
  methods: {
    ...mapMutations(['setEquipmentAcquisition']),
  },
  created() {
    // 用Vuex中的actions节点发起ajax请求
    /**
     * 将所有的请求都追加到一个数组中,避免重复请求
     * 这是一个伪命题,因为请求根本没有走这条线,这里不过是调用了一下发请求的那个方法
     * 这里没有揭穿它的谎言大概率是为了自我安慰吧
     */
    const getData = []

    // 请求技能数据 getList
    getData.push(this.$store.dispatch('getList'))
    // 请求属性选择数据 getattribute
    getData.push(this.$store.dispatch('getattribute'))
    // 请求称号数据 gettitle
    getData.push(this.$store.dispatch('gettitle'))
    // 请求阵型数据 getformation
    getData.push(this.$store.dispatch('getformation'))
    // 请求药物数据 getmedicine
    getData.push(this.$store.dispatch('getmedicine'))
    // 请求变身卡数据 gettransformationcard
    getData.push(this.$store.dispatch('gettransformationcard'))
    // 获取装备的属性数据 getequipment
    getData.push(this.$store.dispatch('getequipment'))
    // 获取宝石属性数据 getgemProperties
    getData.push(this.$store.dispatch('getgemProperties'))
    // 获取附魔属性 getenchantmentAttribute
    getData.push(this.$store.dispatch('getenchantmentAttribute'))
    // 获取宠物装备的属性数据 getpetEquipment
    getData.push(this.$store.dispatch('getpetEquipment'))
    // 获取天赋系统数据 gettalent
    getData.push(this.$store.dispatch('gettalent'))
    // 获取神器系统数据 getartifact
    getData.push(this.$store.dispatch('getartifact'))
    // 获取图鉴系统数据 getatlas
    getData.push(this.$store.dispatch('getatlas'))
    // 获取传输数据名称 gettransferDataName
    getData.push(this.$store.dispatch('gettransferDataName'))
    // 获取玩法推荐数据 getrecommendList
    getData.push(this.$store.dispatch('getrecommendList'))
    // 获取原创文章数据 getarticleList
    getData.push(this.$store.dispatch('getarticleList'))
    // 获取副本列表 getgamesList
    getData.push(this.$store.dispatch('getgamesList'))
    // 获取怪物数据 getmonsterList
    getData.push(this.$store.dispatch('getmonsterList'))
    // 请求宠物天生数据 getpetBorn
    getData.push(this.$store.dispatch('getpetBorn'))
    // 请求附魔数据
    getData.push(this.$store.dispatch('getEnchant'))

    //  查看本地是否有装备图鉴数据,如果有则开启装备数据的获取
    this.$dataCalling({ name: 'equipList' }).then(data => {
      if (data) {
        localStorage.setItem("getEquipData", true)
        this.setEquipmentAcquisition(true)
      }
    }).catch(error => {
      if (localStorage.getItem('equipList')) {
        localStorage.setItem("getEquipData", true)
        this.setEquipmentAcquisition(true)
      }
    }).finally(() => {
      // 获取装备图鉴数据 getequipList 由于装备图鉴数据体积过于庞大所以要开启后才会加载
      if (localStorage.getItem("getEquipData")) {
        this.setEquipmentAcquisition(true)
        console.log("正在获取装备数据,大量数据正在涌来~~")
        getData.push(this.$store.dispatch('getequipList'))
      }
    })

    promiseAll(getData)

    // const superTask = new SuperTask()
    // 请求技能数据 getList
    // superTask.add(this.$store.dispatch('getList')).then(() => {
    //   console.log('技能数据获取成功！')
    // })
    // 请求属性选择数据 getattribute
    // superTask.add(this.$store.dispatch('getattribute')).then(() => {
    //   console.log('基础数据获取成功！')
    // })
    // 请求称号数据 gettitle
    // superTask.add(this.$store.dispatch('gettitle')).then(() => {
    //   console.log('技能数据获取成功！')
    // })
    // 请求阵型数据 getformation
    // superTask.add(this.$store.dispatch('getformation')).then(() => {
    //   console.log('阵型数据获取成功！')
    // })
    // 请求药物数据 getmedicine
    // superTask.add(this.$store.dispatch('getmedicine')).then(() => {
    //   console.log('药物数据获取成功！')
    // })
    // 请求变身卡数据 gettransformationcard
    // superTask.add(this.$store.dispatch('gettransformationcard')).then(() => {
    //   console.log('变身卡数据获取成功！')
    // })
    // 获取装备的属性数据 getequipment
    // superTask.add(this.$store.dispatch('getequipment')).then(() => {
    //   console.log('装备数据获取成功！')
    // })
    // 获取宝石属性数据 getgemProperties
    // superTask.add(this.$store.dispatch('getgemProperties')).then(() => {
    //   console.log('宝石数据获取成功！')
    // })
    // 获取附魔属性 getenchantmentAttribute
    // superTask.add(this.$store.dispatch('getenchantmentAttribute')).then(() => {
    //   console.log('附魔数据获取成功！')
    // })
    // 获取宠物装备的属性数据 getpetEquipment
    // superTask.add(this.$store.dispatch('getpetEquipment')).then(() => {
    //   console.log('宠物装备数据获取成功！')
    // })
    // 获取天赋系统数据 gettalent
    // superTask.add(this.$store.dispatch('gettalent')).then(() => {
    //   console.log('天赋系统获取成功！')
    // })
    // 获取神器系统数据 getartifact
    // superTask.add(this.$store.dispatch('getartifact')).then(() => {
    //   console.log('神器系统获取成功！')
    // })
    // 获取图鉴系统数据 getatlas
    // superTask.add(this.$store.dispatch('getatlas')).then(() => {
    //   console.log('图鉴系统获取成功！')
    // })
    // 获取传输数据名称 gettransferDataName
    // superTask.add(this.$store.dispatch('gettransferDataName')).then(() => {
    //   console.log('基础数据获取成功！')
    // })
    // 获取玩法推荐数据 getrecommendList
    // superTask.add(this.$store.dispatch('getrecommendList')).then(() => {
    //   console.log('玩法推荐数据获取成功！')
    // })
    // 获取原创文章数据 getarticleList
    // superTask.add(this.$store.dispatch('getarticleList')).then(() => {
    //   console.log('文章数据获取成功！')
    // })
    // 获取副本列表 getgamesList
    // superTask.add(this.$store.dispatch('getgamesList')).then(() => {
    //   console.log('副本数据获取成功！')
    // })
    // 获取怪物数据 getmonsterList
    // superTask.add(this.$store.dispatch('getmonsterList')).then(() => {
    //   console.log('怪物数据获取成功！')
    // })
    // 请求宠物天生数据 getpetBorn
    // superTask.add(this.$store.dispatch('getpetBorn')).then(() => {
    //   console.log('宠物获取成功！')
    // })
    // 获取装备图鉴数据 getequipList
    // superTask.add(this.$store.dispatch('getequipList')).then(() => {
    //   console.log('装备图鉴数据获取成功！')
    // })
  },
  beforeCreate() {
    // setTimeout(() => {
    //   const str = this.$route.path
    //   const index = str.indexOf('-')
    //   let result = str.substring(index + 1, str.length)
    //   // 斜线默认跳转首页
    //   if (result === '/') result = 'home'
    //   // 判断是pc端还是移动端
    //   if (this.$equipment()) {
    //     // 利用for in 循环将搜索的对象拼接上去
    //     let element = []
    //     for (let key in this.$route.query) {
    //       element.push(`${key}=${this.$route.query[key]}`)
    //     }
    //     // 如果地址栏有数据就拼接，没有就不拼接
    //     if (element.length) {
    //       this.$router.replace(`/android-${result}?` + element.join('&'))
    //     } else {
    //       // PC端特殊页面，pc融合了其他页面直接返回首页
    //       if (
    //         result === 'IllustratedHandbook' ||
    //         result === 'DataCalculation' ||
    //         result === '/android' ||
    //         result == ''
    //       )
    //         result = 'home'
    //       this.$router.replace(`/android-${result}`)
    //     }
    //   } else {
    //     // 利用for in 循环将搜索的对象拼接上去
    //     let element = []
    //     for (let key in this.$route.query) {
    //       element.push(`${key}=${this.$route.query[key]}`)
    //     }
    //     // 如果地址栏有数据就拼接，没有就不拼接
    //     if (element.length) {
    //       this.$router.replace(`/windows-${result}?` + element.join('&'))
    //     } else {
    //       // 移动端特殊页面，pc融合到了其他页面直接返回首页
    //       if (
    //         result === 'WebsiteTools' ||
    //         result === 'AttackConversion' ||
    //         result === 'WebsiteInformation' ||
    //         result === 'PetGuide' ||
    //         result === 'MonsterManual' ||
    //         result === '/windows' ||
    //         result === 'WeaponEquipmentAtlas' ||
    //         result == ''
    //       )
    //         result = 'home'
    //       this.$router.replace(`/windows-${result}`)
    //     }
    //   }
    // }, 500)

    const str = location.hash.replace(/#\//g, '')
    const index = str.indexOf('-')
    let result = index !== -1 ? str.substring(index + 1, str.length) : '/'
    // 斜线默认跳转首页
    if (result === '/') result = 'home'
    // 判断是pc端还是移动端
    if (this.$equipment()) {
      // PC端特殊页面，pc融合了其他页面直接返回首页
      if (
        result === 'IllustratedHandbook' ||
        result === 'DataCalculation' ||
        result === '/windows' ||
        result == ''
      ) {
        result = 'home'
      }
      this.$router.replace(`/android-${result}`)
    } else {
      // 移动端特殊页面，pc融合到了其他页面直接返回首页
      if (
        result === 'WebsiteTools' ||
        result === 'WebsiteInformation' ||
        result === 'PetGuide' ||
        result === 'MonsterManual' ||
        result === 'WeaponEquipmentAtlas' ||
        result === '/android' ||
        result == ''
      ) {
        result = 'home'
      }

      // 移动端特殊页面,在PC端归结到了同一个功能页
      if (result === 'GradeSuppression' || result === 'AttackRelatedCalculations') {
        result = 'DataCalculation'
      }

      this.$router.replace(`/windows-${result}`)
    }
  }
}
</script>

<style lang="less" scoped>
html {
  font-family: 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB',
    'Microsoft YaHei', '微软雅黑', Arial, sans-serif;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s linear;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.iconfont {
  position: relative;
  display: block;
  left: 50%;
  transform: translateX(-20%);
  margin-bottom: 8px;
}
</style>