exports.install = function (Vue) {
  // 传入融合后的数据  当前选中的武器类型 人物等级
  Vue.prototype.petDataConsolidation = function (list, petLevel = 0) {
    let dataIntegration = {
      label: {}
    }
    // 宠物力量数据
    dataIntegration.petPower = parseInt(list.petPower * (list.petPowerPercentage / 100 + 1) + list.petExtraPower)
    // 宠物体质数据
    dataIntegration.petConstitution = parseInt(list.petConstitution * (list.petConstitutionPercentage / 100 + 1) + list.petExtraConstitution)
    // 宠物敏捷数据
    dataIntegration.petAgile = parseInt(list.petAgile * (list.petAgilePercentage / 100 + 1) + list.petExtraAgility)
    // 宠物智力数据
    dataIntegration.petIntelligence = parseInt(list.petIntelligence * (list.petIntelligencePercentage / 100 + 1) + list.petExtraIntelligence)
    // 宠物感知数据
    dataIntegration.petPerception = parseInt(list.petPerception * (list.petPerceptionPercentage / 100 + 1) + list.petExtraPerception)
    // 宠物等级提升所附加的基础数据
    let basicsH = 0
    let basicsP = 0
    for (let i = 1; i <= petLevel; i++) {
      basicsH += 40
      basicsP += 10
    }
    // 角色生命值----角色初始60生命
    dataIntegration.petLife = parseInt((dataIntegration.petPower * 3 + parseInt(dataIntegration.petConstitution * 6.5) + list.petLife + basicsH + 60) * (list.petLifePercentage / 100 + 1))
    // 角色法力值----角色初始40法力
    dataIntegration.petMana = parseInt((parseInt(dataIntegration.petPerception * 3.5) + parseInt(dataIntegration.petIntelligence * 7.5) + basicsP + 40 + list.petMana) * (list.petManaPercentage / 100 + 1))
    // 出手速度
    dataIntegration.move = parseInt((list.move + dataIntegration.petAgile * 3 + parseInt(dataIntegration.petPerception * 1.5)) * (list.movePercentage / 100 + 1))
    // 武器伤害范围
    // 最低武器伤害
    dataIntegration.minimumWeaponDamage = list.minimumWeaponDamage
    // 最高武器伤害
    dataIntegration.maximumWeaponDamage = list.maximumWeaponDamage
    // 武商拦截,如果武商为NaN则强制为0
    if (Object.is(dataIntegration.minimumWeaponDamage, NaN) || Object.is(dataIntegration.maximumWeaponDamage, NaN)) {
      dataIntegration.minimumWeaponDamage = 0
      dataIntegration.maximumWeaponDamage = 0
    }
    // 宠物攻次
    dataIntegration.attackTimes = (list.attackTimes > 1 ? list.attackTimes : 1)
    // 宠物劈砍攻击力
    dataIntegration.petChopAttackPower = parseInt((list.petChopAttackPower + dataIntegration.petPower * 5 + dataIntegration.petAgile * 3) * (list.petChopAttackPowerPercentage / 100 + 1) * (list.petAttackPowerPercentage / 100 + 1))
    // 攻击力最高100w
    dataIntegration.petChopAttackPower = dataIntegration.petChopAttackPower > 1000000 ? 1000000 : dataIntegration.petChopAttackPower
    // 宠物穿刺攻击力
    dataIntegration.petPiercingAttackPower = parseInt((list.petPiercingAttackPower + dataIntegration.petPower * 5 + dataIntegration.petAgile * 5) * (list.petPiercingAttackPowerPercentage / 100 + 1) * (list.petAttackPowerPercentage / 100 + 1))
    // 攻击力最高100w
    dataIntegration.petPiercingAttackPower = dataIntegration.petPiercingAttackPower > 1000000 ? 1000000 : dataIntegration.petPiercingAttackPower
    // 魔法命中----不同宠物初始魔法命中不同
    dataIntegration.magicHit = parseInt(list.magicHit * (list.magicHitPercentage / 100 + 1))
    // 魔法攻击力
    dataIntegration.petMagicAttackPower = parseInt(parseInt((dataIntegration.petIntelligence * 3 + dataIntegration.petPerception * 2) * ((dataIntegration.magicHit - 100) / 1000 + 1) + list.petMagicAttackPower) * (list.petMagicAttackPowerPercentage / 100 + 1) * (list.petAttackPowerPercentage / 100 + 1))
    // 攻击力最高100w
    dataIntegration.petMagicAttackPower = dataIntegration.petMagicAttackPower > 1000000 ? 1000000 : dataIntegration.petMagicAttackPower
    // 劈砍防御力
    dataIntegration.chopDefense = parseInt((list.chopDefense + dataIntegration.petConstitution * 8) * (list.chopDefensePercentage / 100 + 1))
    // 穿刺防御力
    dataIntegration.punctureDefense = parseInt((list.punctureDefense + dataIntegration.petConstitution * 8) * (list.punctureDefensePercentage / 100 + 1))
    // 魔法防御力
    dataIntegration.magicDefense = parseInt((list.magicDefense + dataIntegration.petIntelligence * 2 + dataIntegration.petPerception * 4) * (list.magicDefensePercentage / 100 + 1))
    // 法力护盾
    dataIntegration.manaShield = parseInt((list.manaShield + dataIntegration.petPerception * 3 + dataIntegration.petIntelligence * 2) * (list.manaShieldPercentage / 100 + 1))
    // 物理命中
    dataIntegration.hit = parseInt(list.hit * (list.hitPercentage / 100 + 1))
    // 强制命中
    dataIntegration.forcedHit = (list.forcedHit + 30) > 70 ? 70 : (list.forcedHit + 30)
    // 破甲
    dataIntegration.armourBreaking = parseInt((list.armourBreaking + dataIntegration.petPower * 3 + dataIntegration.petAgile) * (list.armourBreakingPercentage / 100 + 1))
    // 魔法穿透
    dataIntegration.magicPenetration = parseInt((list.magicPenetration + dataIntegration.petIntelligence * 2 + dataIntegration.petPerception) * (list.magicPenetrationPercentage / 100 + 1))
    // 致命点
    dataIntegration.deadly = parseInt(list.deadly * (list.deadlyPercentage / 100 + 1))
    // 免伤护盾
    dataIntegration.immuneShield = list.immuneShield
    // 状态抵抗
    dataIntegration.state = parseInt((list.state + parseInt(dataIntegration.petPerception * 0.3 + dataIntegration.petIntelligence * 0.1)) * (list.statePercentage / 100 + 1))
    // 洞察
    dataIntegration.insight = parseInt((list.insight + parseInt(dataIntegration.petPerception * 0.2 + dataIntegration.petIntelligence * 0.1)) * (list.insightPercentage / 100 + 1))
    // 格挡
    dataIntegration.block = parseInt(parseInt(list.block + dataIntegration.petAgile * 0.2) * (list.blockPercentage / 100 + 1))
    // 伤害减免
    dataIntegration.damageReduction = parseInt((list.damageReduction + parseInt(dataIntegration.petPower * 0.1 + dataIntegration.petConstitution * 0.2)) * (list.damageReductionPercentage / 100 + 1))
    // 闪避
    dataIntegration.dodge = parseInt(parseInt(list.dodge + (dataIntegration.petAgile + dataIntegration.petPerception) * 0.4) * (list.dodgePercentage / 100 + 1))
    // 反击
    dataIntegration.counterAttack = parseInt(list.counterAttack * (list.counterAttackPercentage / 100 + 1))
    // 魔法反馈
    dataIntegration.magicFeedback = parseInt(list.magicFeedback * (list.magicFeedbackPercentage / 100 + 1))
    // 生命吸收
    dataIntegration.lifeAbsorption = parseInt(list.lifeAbsorption * (list.lifeAbsorptionPercentage / 100 + 1))
    // 法力吸收
    dataIntegration.manaAbsorption = parseInt(list.manaAbsorption * (list.manaAbsorptionPercentage / 100 + 1))
    // 自动恢复生命
    dataIntegration.HPRecovery = parseInt(list.HPRecovery * (list.HPRecoveryPercentage / 100 + 1))
    // 自动恢复法力
    dataIntegration.manaRegeneration = parseInt(list.manaRegeneration * (list.manaRegenerationPercentage / 100 + 1))
    // 无视反击
    dataIntegration.ignorecounterAttack = list.ignorecounterAttack
    // 无视魔法反馈
    dataIntegration.ignoreMagicFeedback = list.ignoreMagicFeedback
    // 无视格挡
    dataIntegration.ignoreBlock = list.ignoreBlock
    // 无视洞察
    dataIntegration.ignoreInsight = list.ignoreInsight
    // 无视状态抵抗
    dataIntegration.disregardState = list.disregardState
    // 无视伤害减免
    dataIntegration.ignoreDamageReduction = list.ignoreDamageReduction

    // 宠物承受伤害(%)
    dataIntegration.petsSufferDamage = list.petsSufferDamage
    // 宠物承受穿刺伤害(%)
    dataIntegration.petsSufferPunctureDamage = list.petsSufferPunctureDamage
    // 宠物承受劈砍伤害(%)
    dataIntegration.petsSufferChopDamage = list.petsSufferChopDamage
    // 宠物承受魔法伤害(%)
    dataIntegration.petsSufferMagicDamage = list.petsSufferMagicDamage
    // 宠物承受诅咒效果(%)
    dataIntegration.petsSufferCurseDamage = list.petsSufferCurseDamage

    // 任务经验
    dataIntegration.taskExperiencePercentage = list.taskExperiencePercentage
    // 战斗经验
    dataIntegration.combatExperiencePercentage = list.combatExperiencePercentage

    return dataIntegration
  }
}