exports.install = function (Vue) {
  Vue.prototype.ConversionOfMartialDamageAttackTimes = function (ruleForm) {
    let { Attack, Lowest, Supreme, LowestDataPanel, SupremeDataPanel, automatic } = ruleForm
    if (automatic === "魔化武器") {
      Attack += 30
    }
    if (automatic === "乾坤剑诀") {
      Attack += 35
    }
    if (automatic === "碧海生潮") {
      Attack += 15
    }
    // 攻次最高为99
    Attack = Attack > 99 ? 99 : Attack
    let BasicAverage = parseInt((Lowest + Supreme) / 2)
    let PanelAverage = parseInt((LowestDataPanel + SupremeDataPanel) / 2)
    let harm = PanelAverage * (Attack + 1)
    let percentage = 0
    let middleman = null
    while (true) {
      if (middleman * Attack >= harm) {
        percentage = percentage.toFixed(2)
        return percentage
      } else {
        middleman = PanelAverage
        percentage += 0.01
        middleman += parseInt(BasicAverage * percentage)
      }
    }
  }
}