exports.install = function (Vue) {
  Vue.prototype.skillAttributeExpiration = function (percentage, dataObject, round, mode, Rage) {
    // 数据叠加保存的数组
    let list = []
    // 扣除的值
    let deduction = 0
    // 把扣除的值存入数组中
    let deductions = []
    percentage /= 100
    for (let i = 1; i <= 30; i++) {
      // 初始化数据
      deduction = 0
      // 闪光弹持续两个回合所以应该在第三回合开始时扣除值
      if (i > round) {
        dataObject += deductions[i - 3]
      }
      // 数据备份保留初始数据
      let dataObjects = dataObject
      // mode判断单开还是混开
      if (mode) {
        if (Rage === 1.5) {
          dataObject = parseInt(dataObject * (1 - percentage))
          if (i % 2 !== 0) {
            dataObject = parseInt(dataObject * (1 - percentage))
          }
        } else {
          for (let j = 1; j <= Rage; j++) {
            dataObject = parseInt(dataObject * (1 - percentage))
          }
        }
      } else {
        if (i % 2 !== 0) {
          if (Rage === 1.5) {
            dataObject = parseInt(dataObject * (1 - percentage) * (1 - percentage))
          } else {
            for (let j = 1; j <= Rage; j++) {
              dataObject = parseInt(dataObject * (1 - percentage))
            }
          }
        }
      }
      deduction = dataObjects - dataObject
      // 如果扣除的值大于初始值,则扣除值等于初始值
      if (deduction > dataObjects) {
        deduction = dataObjects
        dataObject = dataObject < 0 ? 0 : dataObject
      }
      deductions.push(deduction)

      list.push({
        round: `第【${i}】回合`,
        dataObjects: `当前回合初始值为:${dataObjects}`,
        deduction: `扣除的值为:${deduction}`,
        dataObject: `当前剩余的值为:${dataObject}`,
        deductions: `技能属性过期值为:${deductions[i - round] ?? 0}`,
        dataObjectList: dataObject
      })
    }
    return list
  }
}