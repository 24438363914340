exports.install = function (Vue) {
  Vue.prototype.enchantment = function (data, mode) {
    // list数组表示要遍历的对象
    let list = []
    // 当前回合叠加的值
    let superposition = 0
    // 魔化叠加数组
    let enchantment = []
    // 过期的的数据值
    let overdue = 0
    // 攻击力上限
    let upperLimit = 1000000
    // 记录保留第一次到达百万的数据值
    let record = true
    let upper = 0

    for (let i = 1; i <= 30; i++) {

      // 魔化效果持续到第五回合结束,但由于值在结束后扣除这里就直接在第六回合进行扣除
      if (i > 5) {
        data -= overdue
      }
      if (i >= 5) {
        overdue = enchantment[i - 5]
      }
      // 备份当前数据
      let copy = data
      // 数据叠加
      data = parseInt(data * 1.24)
      // 第一次到达百万时改变触发记录时刻
      if (data >= upperLimit) {
        if (record) upper = i
        record = false
      }
      // 最高100w
      data = data > upperLimit ? upperLimit : data
      // 当前叠加的值
      superposition = data - copy
      // 将当前叠加的值放入数组中进行保存
      enchantment.push(superposition)
      // 将数据追加展示
      if (mode) {
        list.push({
          round: `第【${i}】回合`,
          data: `劈砍/穿刺值为:${data}`,
          overdue: `即将过期的劈砍/穿刺值为:${overdue}`,
          superposition: `叠加的劈砍/穿刺值为:${superposition}`
        })
      } else {
        list.push({
          round: `第【${i}】回合`,
          data: `劈砍/穿刺值为:${data}`
        })
      }
      // 到达百万跳出当前循环
      if (data >= upperLimit) break
    }
    return {
      list,
      upper
    }
  }
};
