exports.install = function (Vue) {
  Vue.prototype.MagicHitsAndAttackCounts = function (dataValue, targetLevelL, Attack, localData) {
    // 魔命(魔法命中): (魔命 / 攻击方等级 * 0.1) * 100 / 100
    // parseInt((dataValue / targetLevelL * 0.1) * 100) / 100
    const hitRate = parseInt((dataValue / targetLevelL * 0.1) * 100) / 100
    // 魔法命中率
    const magicHitRate = {
      // 当前攻次魔法命中率
      current: 0,
      // 魔法命中增加10,魔法命中率提升
      magicHitIncrease: 0,
      // 当前攻次魔法命中率
      attackIncrease: 0
    }
    // 魔法命中提升的数值
    let attributeIncrease = 0

    if (hitRate < 1) {
      // 当前攻次魔法不命中率
      const currentMagicHitRate = Math.pow((1 - hitRate), Attack)
      // 当前攻次魔法命中率
      magicHitRate.current = 1 - currentMagicHitRate

      // 魔法命中增加,魔法命中率提升
      while (magicHitRate.magicHitIncrease < 0.0001) {
        attributeIncrease += 10
        magicHitRate.magicHitIncrease = decimalSubtraction(currentMagicHitRate, Math.pow((1 - parseInt(((dataValue + attributeIncrease) / targetLevelL * 0.1) * 100) / 100), Attack))
      }

      // 当前攻次魔法命中率
      magicHitRate.attackIncrease = decimalSubtraction(currentMagicHitRate, Math.pow((1 - hitRate), (Attack + 1)))
    }

    // console.log(magicHitRate)

    return {
      hitRate,
      magicHitRate,
      attributeIncrease
    }

    // 小数减法
    function decimalSubtraction(value, value2) {
      const count = Math.max(("" + value).split(".")[1]?.length, ("" + value2).split(".")[1]?.length) || 0
      const temp = Math.pow(10, count)
      return (value * temp - value2 * temp) / temp
    }
  }
}