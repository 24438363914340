exports.install = function (Vue) {
	Vue.prototype.InjuryCalculation = function (ruleForm, list, rounds, bloodVolume, monsterList) {
		// Object.keys() 遍历对象键值并存入数组
		// includes() 检测数组中是否包含某个元素,返回布尔值
		// if (!Object.keys(ruleForm).includes("att") && !Object.keys(ruleForm).includes("Rage")) {
		//   console.log("你是武伤浮动计算,你跑不掉了~~~");
		// }
		// if (Object.keys(ruleForm).includes("att")) {
		//   console.log("你是武伤浮动自定义攻击,你跑不掉了~~~");
		// }
		// if (Object.keys(ruleForm).includes("Rage")) {
		//   console.log("你是刷伤害计算,你跑不掉了~~~");
		// }

		// 利用强命数据值判断用户的攻击方式
		// if (ruleForm.forcedHit) {
		//   console.log(true);
		// } else {
		//   console.log(false);
		// }

		// Lowest(最低武器伤害),Supreme(最高武器伤害),Attack(攻次),frequency(主动技能攻次),forcedHit(强命值)
		// att(攻击力),Rage(狂暴个数),occupation(攻击类型),automatic(自动技能),loop(循环计算次数)

		// 将传入的对象的所有键值存在数组中方便后面有需求时使用
		let arr = Object.keys(ruleForm)
		// 把对象中的基础常用数据解构出来
		let { Lowest, Supreme, Attack, frequency, automatic, loop, occupation } = ruleForm
		//最低最高武器伤害位置纠正
		if (Lowest > Supreme) {
			let room = Lowest
			Lowest = Supreme
			Supreme = room
		}

		// 生成的最低/最高武伤均值---武伤均值
		let generateMinimum = 0
		let generateHighest = 0
		let averageWeapon = parseInt((Lowest + Supreme) / 2)

		//最低/最高---平均(武器)伤害
		let minimumDamage = 0
		let maximumDamage = 0
		let averageWeaponDamage = 0

		//最低/最高伤害所对应的平均武器伤害(武伤)
		let minSupremeAverage = 0
		let maxSupremeAverage = 0

		//平均伤害---武器伤害(武伤)均值伤害
		// 因为平均武伤不会出现武伤浮动的情况所以平均伤害就是他自己
		let averageInjury = 0

		// 百万攻击力
		let million = 1000000

		// 实际攻次
		let actualattacktimes = 0

		// 攻次计算
		// 副本回合估算不能直接加成魔化总攻次
		if (!arr.includes("round")) {
			if (automatic === "魔化武器") {
				Attack += 30
			}
		}

		// 刷伤害计算页面暴力默认用完全狂暴
		if (arr.includes("Rage")) {
			Attack += 24 + (ruleForm.Rage * 7)
		}
		// 总攻次 = 被动 + 主动
		Attack += frequency
		// 攻次最高99
		Attack = Attack > 99 ? 99 : Attack

		// 副本回合估算数据
		let copy = []
		if (arr.includes("round")) {
			loop = 30
			// 怪物强命血量和总血量
			var bloodVolumeforcedHit = bloodVolume
		}

		// 伤害计算
		for (let i = 1; i <= loop; i++) {
			// 副本回合估算攻次是按回合叠加
			if (arr.includes("round") && automatic === "魔化武器") {
				if (i <= 5) {
					Attack += 6
				}
			}

			if (arr.includes("round") && automatic === "混开魔化") {
				if (i <= 5 && i % 2 !== 0) {
					Attack += 6
				}
			}

			// 攻次最高99
			Attack = Attack > 99 ? 99 : Attack

			// 随机生成格挡---block为格挡
			let block = 0
			// 物理攻击才会有格挡
			if (occupation === '物理攻击') {
				// 优化格挡计算---格挡最高为7
				// let block_random = 0.7
				// for (let i = 0; i < Attack; i++) {
				//   if (block_random >= 0.1 && Math.random() <= block_random) {
				//     block++
				//     block_random -= 0.1
				//   }
				// }

				// 格挡最高为7
				if (Attack < 7) {
					block = Math.round(Math.random() * Attack)
				} else {
					block = Math.round(Math.random() * 7)
				}
			}
			//实际攻次
			actualattacktimes = Attack - block

			// 武伤均值计算---生成武伤的均值用于数据计算
			let generateMean = 0
			for (let i = 0; i < Attack; i++) {
				// 动态生成武伤
				let room = Math.round(Math.random() * (Supreme - Lowest) + Lowest)
				generateMean += room
			}

			// 平均武伤 = 武伤总值 / 循环次数
			generateMean = parseInt(generateMean / Attack)

			// 副本回合估算页面骚操作
			if (arr.includes("round")) {
				if (rounds <= i) {
					if (occupation === '物理攻击') {
						if (bloodVolumeforcedHit === '武伤浮动强命伤害已完成任务') break
					} else {
						if (bloodVolume === '武伤浮动伤害已完成任务') break
					}
					// 当前回合攻击力
					let data = list[i - 1].datas
					// 当前回合的伤害----攻击力考虑格挡的影响
					let hurts = parseInt((generateMean + parseInt(data / Attack)) * actualattacktimes * 1.5 * 0.3)
					hurts = attackLimit(hurts, actualattacktimes)
					// 当前回合实际伤害
					let hurt = 0
					// 怪物血量剩余==>怪物还有血量并且不为物理攻击,因为物理攻击还要考虑闪避问题
					if (bloodVolume && (occupation !== '物理攻击')) {
						if (bloodVolume > hurts) {
							bloodVolume -= hurts
							hurt = hurts
						} else {
							hurt = bloodVolume
							bloodVolume = '武伤浮动伤害已完成任务'
						}
					}
					if (occupation === '物理攻击') {
						// 当前回合命中
						let hit = list[i - 1].hits
						// 当前回合怪物闪避
						let currentDodge = monsterList[i - 1].dataObjectList
						// 物理攻击伤害经过一次伤害转义==>命中率=当前命中/当前怪物闪避
						// 命中率
						let hitRate = 0
						// 如果怪物没有闪避则命中率为100%
						if (currentDodge === 0) {
							hitRate = 100
						} else {
							hitRate = (hit - currentDodge)
						}
						// 判断命中率是否不可计算,如果不可计算则为0
						if (isNaN(hitRate)) {
							hitRate = 0
						}
						// 命中率最低为0,最高为100
						hitRate = hitRate < 0 ? 0 : hitRate
						hitRate = hitRate > 100 ? 100 : hitRate
						// 当前回合强命伤害
						let { forcedHit } = ruleForm
						forcedHit /= 100
						// 强命伤害
						let hurtforcedHit = parseInt(hurts * forcedHit)
						// 检测命中和闪避的关系
						if (hitRate < (forcedHit * 100)) {
							// 角色当前命中率小于强制命中率则使用强制命中率
							hurts = hurtforcedHit
						} else {
							// 角色当前命中率大于强制命中率则考虑命中率是否为100%
							hurts = parseInt(hurts * (hitRate / 100).toFixed(2))
						}
						// 怪物血量剩余
						if (bloodVolume) {
							if (bloodVolume > hurts) {
								bloodVolume -= hurts
								hurt = hurts
							} else {
								hurt = bloodVolume
								bloodVolume = '武伤浮动伤害已完成任务'
							}
						}
						// 怪物强命血量剩余
						if (bloodVolumeforcedHit) {
							// console.log(bloodVolumeforcedHit);
							// console.log(hurtforcedHit);
							if (bloodVolumeforcedHit > hurtforcedHit) {
								bloodVolumeforcedHit -= hurtforcedHit
							} else {
								hurtforcedHit = bloodVolumeforcedHit
								bloodVolumeforcedHit = '武伤浮动强命伤害已完成任务'
							}
						}
						copy.push({
							// 当前回合
							round: `第【${i}】回合`,
							// 强命值为
							forcedHit: `强制命中率:${parseInt(forcedHit * 100)}%`,
							// 当前回合平均武器伤害
							generateMean: `当前回合平均武器伤害:${generateMean}`,
							// 当前回合攻次为
							actualattacktimes: `当前回合攻次为:${actualattacktimes}`,
							// 当前回合攻击力
							data: `当前回合攻击力:${data}`,
							// 当前回合命中
							hit: `当前回合物理命中:${hit}`,
							// 当前回合怪物闪避
							currentDodge: `当前回合怪物闪避:${currentDodge}`,
							// 当前回合命中率
							hitRate: `当前回合命中率:${hitRate}%`,
							// 当前回合伤害
							hurt: `当前回合伤害:${hurt}`,
							// 当前怪物所剩血量
							bloodVolume: `当前回合怪物所剩血量:${bloodVolume}`,
							// 当前回合强命伤害
							hurtforcedHit: `当前回合强命伤害:${hurtforcedHit}`,
							// 强命当前怪物所剩血量
							bloodVolumeforcedHit: `当前回合强命攻击怪物所剩血量:${bloodVolumeforcedHit}`
						})
					} else {
						copy.push({
							// 当前回合
							round: `第【${i}】回合`,
							// 当前回合攻击力
							data: `当前回合攻击力:${data}`,
							// 当前回合平均武器伤害
							generateMean: `当前回合平均武器伤害:${generateMean}`,
							// 当前回合攻次为
							actualattacktimes: `当前回合攻次为:${actualattacktimes}`,
							// 当前回合伤害
							hurt: `当前回合伤害:${hurt}`,
							// 当前怪物所剩血量
							bloodVolume: `当前回合怪物所剩血量:${bloodVolume}`,
						})
					}
				}
			}

			// 初始化生成的武伤
			if (i === 1) {
				generateHighest = generateMinimum = generateMean
			}
			// 保证最高最低武器伤害(武伤)的有效性
			if (generateHighest < generateMean) {
				generateHighest = generateMean
			}
			if (generateMinimum > generateMean) {
				generateMinimum = generateMean
			}

			// 生成武器伤害(武伤)基础伤害数据值---作为中间值使用
			// 因为平均武器伤害(武伤)不存在最高最低伤害,因此不需要中间值转换
			let generateData = 0
			// 进行页面判断,开始分模块儿计算
			// 攻击力--基础数据计算----攻击力考虑格挡的影响
			if (arr.includes("att")) {
				generateData = attackLimit(parseInt((generateMean + parseInt(ruleForm.att / Attack)) * actualattacktimes * 1.5), actualattacktimes)
				averageWeaponDamage = attackLimit(parseInt((averageWeapon + parseInt(ruleForm.att / Attack)) * actualattacktimes * 1.5), actualattacktimes)
			} else {
				generateData = attackLimit(parseInt((generateMean + parseInt(million / Attack)) * actualattacktimes * 1.5), actualattacktimes)
				averageWeaponDamage = attackLimit(parseInt((averageWeapon + parseInt(million / Attack)) * actualattacktimes * 1.5), actualattacktimes)
			}

			// 刷伤害计算不考虑格挡
			if (arr.includes("Rage")) {
				generateData = attackLimit(parseInt((generateMean * Attack + million) * 1.5), actualattacktimes)
				averageWeaponDamage = attackLimit(parseInt((averageWeapon * Attack + million) * 1.5), actualattacktimes)
			}

			// 最低/最高伤害赋值
			//第一回合赋予初值
			if (i === 1) {
				maximumDamage = minimumDamage = generateData
				maxSupremeAverage = minSupremeAverage = generateMean
			}
			//最大伤害小于当前伤害,将当前伤害作为最大伤害---最高最低伤害对应到具体武伤数据上(刷伤害页面特色)
			if (maximumDamage < generateData) {
				maximumDamage = generateData
				maxSupremeAverage = generateMean
			}
			//最小伤害大于当前伤害,将当前伤害作为最小伤害---最高最低伤害对应到具体武伤数据上(刷伤害页面特色)
			if (minimumDamage > generateData) {
				minimumDamage = generateData
				minSupremeAverage = generateMean
			}
			//生成武伤平均伤害
			averageInjury += generateData
		}
		//生成武伤的平均伤害值
		averageInjury = parseInt(averageInjury / loop)

		// 页面判断,数据值输出(暴露)

		// 副本回合估算数据
		if (arr.includes("round")) {
			return copy
		}

		// 刷伤害页面
		if (arr.includes("Rage")) {
			return {
				// 返回刷伤害页面唯一标识,方便后续页面计算
				Rage: true,
				// 最低武器伤害
				Lowest,
				// 最高武器伤害
				Supreme,
				// 武器伤害平均值
				averageWeapon,
				// 攻次
				Attack,
				// 计算次数
				loop,
				// 最低伤害
				minimumDamage: parseInt(minimumDamage * 1.3),
				// 最低伤害所对应的武伤均值
				minSupremeAverage,
				// 最高伤害
				maximumDamage: parseInt(maximumDamage * 1.3),
				// 最高伤害所对应的武伤均值
				maxSupremeAverage,
				// 生成武伤的平均伤害值
				averageInjury: parseInt(averageInjury * 1.3),
				// 武伤均值伤害
				averageWeaponDamage: parseInt(averageWeaponDamage * 1.3)
			}
		}

		// 伤害计算页面
		// 有强命返回的数据---有强命意味着是物理攻击
		if (occupation === '物理攻击') {
			let { forcedHit } = ruleForm
			forcedHit /= 100
			return {
				// 返回强命状态,便于后续页面判断
				forcedHit: true,
				// 最低武器伤害
				Lowest,
				// 最高武器伤害
				Supreme,
				// 武器伤害平均值
				averageWeapon,
				// 生成最低武器伤害
				generateMinimum,
				// 生成最高武器伤害
				generateHighest,
				// 攻次
				Attack,
				// 计算次数
				loop,
				// 全减益免疫伤害范围
				minimumDamage,
				maximumDamage,
				// 生成武伤的平均伤害值
				averageInjury,
				// 全减益免疫伤害(平均武伤)
				averageWeaponDamage,
				// 全减益免疫强命伤害范围
				minimumDamageforcedHit: parseInt(minimumDamage * forcedHit),
				maximumDamageforcedHit: parseInt(maximumDamage * forcedHit),
				// 全减益免疫强命伤害(平均武伤)
				averageWeaponDamageforcedHit: parseInt(averageWeaponDamage * forcedHit),

				// 双状态伤害范围 state
				stateminimumDamage: parseInt(minimumDamage * 1.3),
				statemaximumDamage: parseInt(maximumDamage * 1.3),
				// 双状态生成武伤的平均伤害值
				stateaverageInjury: parseInt(averageInjury * 1.3),
				// 双状态伤害(平均武伤)
				stateaverageWeaponDamage: parseInt(averageWeaponDamage * 1.3),
				// 双状态强命伤害范围 forcedHit
				stateminimumDamageforcedHit: parseInt(minimumDamage * 1.3 * forcedHit),
				statemaximumDamageforcedHit: parseInt(maximumDamage * 1.3 * forcedHit),
				// 双状态强命伤害(平均武伤)
				stateaverageWeaponDamageforcedHit: parseInt(averageWeaponDamage * 1.3 * forcedHit),

				// 魔攻无效全减益免疫伤害范围 basicMagic
				magicminimumDamage: parseInt(minimumDamage * 0.3),
				magicmaximumDamage: parseInt(maximumDamage * 0.3),
				// 魔攻无效生成武伤的平均伤害值
				magicaverageInjury: parseInt(averageInjury * 0.3),
				// 魔攻无效全减益免疫伤害(平均武伤)
				magicaverageWeaponDamage: parseInt(averageWeaponDamage * 0.3),
				// 魔攻无效全减益免疫强命伤害范围 forcedHit
				magicminimumDamageforcedHit: parseInt(minimumDamage * 0.3 * forcedHit),
				magicmaximumDamageforcedHit: parseInt(maximumDamage * 0.3 * forcedHit),
				// 魔攻无效全减益免疫强命伤害(平均武伤)
				magicaverageWeaponDamageforcedHit: parseInt(averageWeaponDamage * 0.3 * forcedHit),

				// 魔攻无效双状态伤害范围 magicstate
				magicstateminimumDamage: parseInt(minimumDamage * 0.3 * 1.3),
				magicstatemaximumDamage: parseInt(maximumDamage * 0.3 * 1.3),
				// 魔攻无效双状态生成武伤的平均伤害值
				magicstateaverageInjury: parseInt(averageInjury * 0.3 * 1.3),
				// 魔攻无效双状态伤害(平均武伤)
				magicstateaverageWeaponDamage: parseInt(averageWeaponDamage * 0.3 * 1.3),
				// 魔攻无效双状态强命伤害范围 forcedHit
				magicstateminimumDamageforcedHit: parseInt(minimumDamage * 0.3 * 1.3 * forcedHit),
				magicstatemaximumDamageforcedHit: parseInt(maximumDamage * 0.3 * 1.3 * forcedHit),
				// 魔攻无效双状态强命伤害(平均武伤)
				magicstateaverageWeaponDamageforcedHit: parseInt(averageWeaponDamage * 0.3 * 1.3 * forcedHit),
			}
		}
		// 没有强命返回的数据---没有强命意味着是魔法攻击
		return {
			// 最低武器伤害
			Lowest,
			// 最高武器伤害
			Supreme,
			// 武伤平均值
			averageWeapon,
			// 生成最低武器伤害
			generateMinimum,
			// 生成最高武器伤害
			generateHighest,
			// 攻次
			Attack,
			// 计算次数
			loop,
			// 全减益免疫伤害范围
			minimumDamage,
			maximumDamage,
			// 生成武伤的平均伤害值
			averageInjury,
			// 全减益免疫伤害(平均武伤)
			averageWeaponDamage,

			// 双状态伤害范围 state
			stateminimumDamage: parseInt(minimumDamage * 1.3),
			statemaximumDamage: parseInt(maximumDamage * 1.3),
			// 双状态生成武伤的平均伤害值
			stateaverageInjury: parseInt(averageInjury * 1.3),
			// 双状态伤害(平均武伤)
			stateaverageWeaponDamage: parseInt(averageWeaponDamage * 1.3),
		}

		// console.log(`最低/最高武伤:${Lowest}------${Supreme}`);
		// console.log(`武伤平均值:${averageWeapon}`);
		// console.log(`当前攻次:${Attack}`);
		// console.log(`生成的最低/最高武伤均值:${generateMinimum}------${generateHighest}`);
		// console.log(`最低/最高伤害:${minimumDamage}------${maximumDamage}`);
		// console.log(`最低/最高伤害所对应的武伤均值:${minSupremeAverage}------${maxSupremeAverage}`);
		// console.log(`武伤均值伤害:${averageWeaponDamage}`);
		// console.log(`生成武伤的平均伤害值:${averageInjury}`);
	}
	// 单攻次攻击力上限
	function attackLimit(aggressivity, Attack) {
		// 最终伤害在±5%之间浮动--由worldH5作者、晨风提出，由worldH5作者、晨风、冰蝶、最帅汤布丽布验证验证
		aggressivity = parseInt((Math.random() * (105 - 95 + 1) + 95) / 100 * aggressivity)
		// 单攻次伤害上限418758
		aggressivity = (aggressivity / Attack) < 418758 ? (aggressivity / Attack) : 418758
		return parseInt(aggressivity * Attack)
	}
}