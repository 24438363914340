import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios"
import { get, set, update, createStore, keys } from 'idb-keyval';
const customStore = createStore('treefuture', 'attribute');

Vue.use(Vuex)

export default new Vuex.Store({
	// 公共数据源
	state: {
		// 技能数组
		list: [],
		// 展示版本
		edition: '古德',
		// 技能数组默认选中
		activeName: "all",
		// 额外属性数组
		attributes: [],
		//  额外属性数组默认选中
		attributeName: "physical",
		// 称号数组
		title: [],
		// 选中的称号
		checktitle: [],
		// 宠物天生属性
		petBorn: [],
		// 选中的宠物天生属性
		checkPetBorn: [],
		// 阵型数组
		formation: [],
		// 选中的阵型
		checkformation: [],
		// 药物数组
		medicine: [],
		// 使用的药物
		checkmedicine: [],
		// 变身卡数组
		transformationcard: [],
		// 使用的变身卡
		checktransformationcard: [],
		// 装备属性
		equipment: [],
		// 宠物装备属性
		petEquipment: [],
		// 数据展示---初始默认展示"战士的被动技能"
		datadisplay: "warrior-passive",
		// 当技能展示为数据同源时，选择展示的技能
		skillView: "all",
		// 天赋系统
		talent: [],
		// 天赋选择数据展示
		talentSelection: [],
		// 神器系统
		artifact: [],
		// 神器选择数据展示
		artifactSelection: [],
		// 图鉴系统
		atlas: [],
		// 图鉴选择数据展示
		atlasSelection: [],
		// 宝石属性数据
		gemProperties: [],
		// 附魔属性
		enchantmentAttribute: [],
		// 传输数据名称
		transferDataName: [],
		// 玩法推荐列表
		recommendList: [],
		// 原创文章列表
		articleList: [],
		// 怪物列表
		monsterList: [],
		// 装备列表
		equipmentList: [],
		// 所有的功能组件,移动端使用
		toolList: [{
			name: "魔化堆叠",
			icon: "xiangmu",
			router: "/android-EnchantStack",
			checked: false
		},
		{
			name: "魔化战斗堆叠",
			icon: "edit",
			router: "/android-DemonizedBattle",
			checked: false
		},
		{
			name: "法师自动堆叠",
			icon: "qr",
			router: "/android-MageStack",
			checked: false
		},
		{
			name: "副本回合估算",
			icon: "jisuan",
			router: "/android-CopyRoundEstimation",
			checked: false
		},
		{
			name: "人物属性计算",
			icon: "a-ziyuan19",
			router: "/android-AttributeCalculation",
			checked: false
		},
		{
			name: "玩法推荐",
			icon: "airec",
			router: "/android-PlayList",
			checked: false
		},
		{
			name: "本地收藏夹",
			icon: "shoucangjia",
			router: "/android-LocalFavorites",
			checked: false
		},
		{
			name: "宠物属性计算",
			icon: "a-ziyuan20",
			router: "/android-PetCalculation",
			checked: false
		},
		{
			name: "技能、天赋及其他数据展示",
			icon: "newspaper-o",
			router: "/android-DataDisplay",
			checked: false
		},
		{
			name: "武商浮动计算",
			icon: "lilvfudongguize",
			router: "/android-WeaponDamageFloat",
			checked: false
		},
		{
			name: "刷伤害计算",
			icon: "chart-trending-o",
			router: "/android-BrushInjury",
			checked: false
		},
		{
			name: "文档攻略",
			icon: "shujujiaohuanzhongxin",
			router: "/android-ArticleDisplay",
			checked: false
		},
		{
			name: "技能属性计算",
			icon: "a-ziyuan17",
			router: "/android-SkillAttribute",
			checked: false
		},
		{
			name: "怪物图鉴",
			icon: "guaiwu",
			router: "/android-MonsterManual",
			checked: false
		},
		{
			name: "宠物图鉴",
			icon: "chongwu",
			router: "/android-PetGuide",
			checked: false
		},
		{
			name: "装备图鉴",
			icon: "dengji",
			router: "/android-WeaponEquipmentAtlas",
			checked: false
		},
		{
			name: "等级压制",
			icon: "shangpinyuji",
			router: "/android-GradeSuppression",
			checked: false
		},
		{
			name: "副本简介",
			icon: "windmill",
			router: "/android-GamesIntroduction",
			checked: false
		},
		{
			name: "攻次相关计算",
			icon: "caogao",
			router: "/android-AttackRelatedCalculations",
			checked: false
		},
		{
			name: "网站工具介绍",
			icon: "chuangyizhan",
			router: "/android-ToolIntroduction",
			checked: false
		}],
		// 副本列表
		gamesList: [],
		// 推荐方式  true代表顺序推荐,false代表随机推荐
		recommendationMethod: true,
		// 装备获取的状态
		equipmentAcquisition: false,
		enchantList: []
	},
	// 数据源包装
	getters: {
		// 各职业的被动技能
		passiveExhibition(state, getters) {
			if (state.activeName === "warrior") {
				let list = []
				// console.log(state.list.warrior.passive);
				state.list.warrior.passive.filter(item => {
					if (item.version.includes(state.edition)) list.push(item)
				})

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}

			if (state.activeName === "swordsman") {
				let list = []
				state.list.swordsman.passive.filter(item => {
					if (item.version.includes(state.edition)) list.push(item)
				})

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}

			if (state.activeName === "xiuZhen") {
				let list = []
				state.list.xiuZhen.passive.filter(item => {
					if (item.version.includes(state.edition)) list.push(item)
				})

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}

			if (state.activeName === "master") {
				let list = []
				state.list.master.passive.filter(item => {
					if (item.version.includes(state.edition)) list.push(item)
				})

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}

			if (state.activeName === "sages") {
				let list = []
				state.list.sages.passive.filter(item => {
					if (item.version.includes(state.edition)) list.push(item)
				})

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}

			if (state.activeName === "Woosung") {
				let list = []
				state.list.Woosung.passive.filter(item => {
					if (item.version.includes(state.edition)) list.push(item)
				})

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}

			if (state.activeName === "gunKing") {
				let list = []
				state.list.gunKing.passive.filter(item => {
					if (item.version.includes(state.edition)) list.push(item)
				})

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}

			// 锤师/岚舞技能
			if (state.activeName === "basicsHammerMaster") {
				let list = []
				state.list.basicsHammerMaster.passive.filter(item => {
					if (item.version.includes(state.edition)) list.push(item)
				})

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}

			if (state.activeName === "basicsHazeDance") {
				let list = []
				state.list.basicsHazeDance.passive.filter(item => {
					if (item.version.includes(state.edition)) list.push(item)
				})

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}

			if (state.activeName === "appletsHammerMaster") {
				let list = []
				state.list.appletsHammerMaster.passive.filter(item => {
					if (item.version.includes(state.edition)) list.push(item)
				})

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}

			if (state.activeName === "appletsHazeDance") {
				let list = []
				state.list.appletsHazeDance.passive.filter(item => {
					if (item.version.includes(state.edition)) list.push(item)
				})

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}

			// 装备技能
			if (state.activeName === "appletsEquipment") {
				let list = []
				state.list.appletsEquipment.passive.filter(item => {
					if (item.version.includes(state.edition)) list.push(item)
				})

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}

			if (state.activeName === "basicsEquipment") {
				let list = []
				state.list.basicsEquipment.passive.filter(item => {
					if (item.version.includes(state.edition)) list.push(item)
				})

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}

			if (state.activeName === "worldh5Equipment") {
				let list = []
				state.list.worldh5Equipment.passive.filter(item => {
					if (item.version.includes(state.edition)) list.push(item)
				})

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}

			// 全部数据
			if (state.activeName === "all") {
				let list = []
				// 除宠物外所有被动技能合集
				for (let key in state.list) {
					if (state.list[key].passive) {
						state.list[key].passive.filter(item => {
							if (!item.identifying.label.includes("宠物") && !item.identifying.label.includes("怪物专属") && item.version.includes(state.edition)) {
								list.push(item)
							}
						})
					}

				}

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}
		},
		// 对技能属性进行特殊处理,只返回宠物相关的技能
		petPassiveExhibition(state, getters) {
			// 宠物数据
			if (state.activeName === "petSpecificSkills") {
				let list = []
				state.list.petSpecificSkills.passive.filter(item => {
					if (item.version.includes(state.edition)) list.push(item)
				})

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}

			if (state.activeName === "appletsPetEquipment") {
				let list = []
				state.list.appletsPetEquipment.passive.filter(item => {
					if (item.version.includes(state.edition)) list.push(item)
				})

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}

			if (state.activeName === "warrior") {
				let list = []
				state.list.warrior.passive.filter(item => {
					if (item.skillAttribute.find(items => items.includes("宠物"))) {
						if (item.version.includes(state.edition)) list.push(item)
					}
				})

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}

			if (state.activeName === "swordsman") {
				let list = []
				state.list.swordsman.passive.filter(item => {
					if (item.skillAttribute.find(items => items.includes("宠物"))) {
						if (item.version.includes(state.edition)) list.push(item)
					}
				})

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}

			if (state.activeName === "xiuZhen") {
				let list = []
				state.list.xiuZhen.passive.filter(item => {
					if (item.skillAttribute.find(items => items.includes("宠物"))) {
						if (item.version.includes(state.edition)) list.push(item)
					}
				})

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}

			if (state.activeName === "master") {
				let list = []
				state.list.master.passive.filter(item => {
					if (item.skillAttribute.find(items => items.includes("宠物"))) {
						if (item.version.includes(state.edition)) list.push(item)
					}
				})

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}

			if (state.activeName === "sages") {
				let list = []
				state.list.sages.passive.filter(item => {
					if (item.skillAttribute.find(items => items.includes("宠物"))) {
						if (item.version.includes(state.edition)) list.push(item)
					}
				})

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}

			if (state.activeName === "Woosung") {
				let list = []
				state.list.Woosung.passive.filter(item => {
					if (item.skillAttribute.find(items => items.includes("宠物"))) {
						if (item.version.includes(state.edition)) list.push(item)
					}
				})

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}

			if (state.activeName === "gunKing") {
				let list = []
				state.list.gunKing.passive.filter(item => {
					if (item.skillAttribute.find(items => items.includes("宠物"))) {
						if (item.version.includes(state.edition)) list.push(item)
					}
				})

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}

			if (state.activeName === "appletsHammerMaster") {
				let list = []
				state.list.appletsHammerMaster.passive.filter(item => {
					if (item.skillAttribute.find(items => items.includes("宠物"))) {
						if (item.version.includes(state.edition)) list.push(item)
					}
				})

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}

			if (state.activeName === "appletsHazeDance") {
				let list = []
				state.list.appletsHazeDance.passive.filter(item => {
					if (item.skillAttribute.find(items => items.includes("宠物"))) {
						if (item.version.includes(state.edition)) list.push(item)
					}
				})

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}

			if (state.activeName === "appletsEquipment") {
				let list = []
				state.list.appletsEquipment.passive.filter(item => {
					if (item.skillAttribute.find(items => items.includes("宠物"))) {
						if (item.version.includes(state.edition)) list.push(item)
					}
				})

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}
			if (state.activeName === "basicsEquipment") {
				let list = []
				state.list.basicsEquipment.passive.filter(item => {
					if (item.skillAttribute.find(items => items.includes("宠物"))) {
						if (item.version.includes(state.edition)) list.push(item)
					}
				})

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}
			if (state.activeName === "worldh5Equipment") {
				let list = []
				state.list.worldh5Equipment.passive.filter(item => {
					if (item.skillAttribute.find(items => items.includes("宠物"))) {
						if (item.version.includes(state.edition)) list.push(item)
					}
				})

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}

			// 迅捷宠物技能
			if (state.activeName === "quickPetSkill") {
				let list = []
				state.list.quickPetSkill.passive.filter(item => {
					// 本身就是宠物技能的就不用技能属性带有宠物属性了
					// if (item.skillAttribute.find(items => items.includes("宠物"))) {}
					if (item.version.includes(state.edition)) list.push(item)
				})

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}

			// 睿智宠物技能
			if (state.activeName === "smartPetSkill") {
				let list = []
				state.list.smartPetSkill.passive.filter(item => {
					// 本身就是宠物技能的就不用技能属性带有宠物属性了
					// if (item.skillAttribute.find(items => items.includes("宠物"))) {}
					if (item.version.includes(state.edition)) list.push(item)
				})

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}

			// 勇猛宠物技能
			if (state.activeName === "bravePetSkill") {
				let list = []
				state.list.bravePetSkill.passive.filter(item => {
					// 本身就是宠物技能的就不用技能属性带有宠物属性了
					// if (item.skillAttribute.find(items => items.includes("宠物"))) {}
					if (item.version.includes(state.edition)) list.push(item)
				})

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}

			// 全部数据
			if (state.activeName === "all") {
				let list = []
				// 所有宠物相关被动技能合集
				for (let key in state.list) {
					if (state.list[key].passive) {
						state.list[key].passive.filter(item => {
							if ((item.identifying.label.includes("宠物") || item.skillAttribute.find(items => items.includes("宠物"))) && !item.identifying.label.includes("怪物专属") && item.version.includes(state.edition)) {
								list.push(item)
							}
						})
					}

				}

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}
		},
		// 被动技能选择情况(数据持续累加返回,以达到版本切换效果)
		passiveSkillSelection(state) {
			let list = []
			for (const key in state.list) {
				// state.list[key].passive.filter(item => item.checked)
				if (state.list[key].passive) {
					state.list[key].passive.filter(item => {
						if (item.children) {
							item.children.skillLevel.filter(items => {
								// 有些只有一级的技能没有checked属性
								if (items.checked) {
									list.push({ ...items, skillType: 'passive' })
								}
							})
						} else {
							if (item.checked) {
								// filter,find返回的都是条件成立的对象,不过filter是以数组的形式返回而find是返回符合条件的元素
								// state.list[key].passive.filter(item => item.checked).length
								// list.push(state.list[key].passive.find(item => item.checked))
								// 这里item就是选出来的最终数据了,不用再做一次筛选
								list.push({ ...item, skillType: 'passive' })
							}
						}
					})
				}
			}

			return list
		},
		// 各职业的主动技能
		activeExhibition(state, getters) {
			if (state.activeName === "warrior") {
				let list = []
				state.list.warrior.active.filter(item => {
					if (item.version.includes(state.edition)) list.push(item)
				})

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}

			if (state.activeName === "swordsman") {
				let list = []
				state.list.swordsman.active.filter(item => {
					if (item.version.includes(state.edition)) list.push(item)
				})

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}

			if (state.activeName === "xiuZhen") {
				let list = []
				state.list.xiuZhen.active.filter(item => {
					if (item.version.includes(state.edition)) list.push(item)
				})

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}

			if (state.activeName === "master") {
				let list = []
				state.list.master.active.filter(item => {
					if (item.version.includes(state.edition)) list.push(item)
				})

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}

			if (state.activeName === "sages") {
				let list = []
				state.list.sages.active.filter(item => {
					if (item.version.includes(state.edition)) list.push(item)
				})

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}

			if (state.activeName === "Woosung") {
				let list = []
				state.list.Woosung.active.filter(item => {
					if (item.version.includes(state.edition)) list.push(item)
				})

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}

			if (state.activeName === "gunKing") {
				let list = []
				state.list.gunKing.active.filter(item => {
					if (item.version.includes(state.edition)) list.push(item)
				})

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}

			// 锤师/岚舞技能
			if (state.activeName === "basicsHammerMaster") {
				let list = []
				state.list.basicsHammerMaster.active.filter(item => {
					if (item.version.includes(state.edition)) list.push(item)
				})

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}

			if (state.activeName === "basicsHazeDance") {
				let list = []
				state.list.basicsHazeDance.active.filter(item => {
					if (item.version.includes(state.edition)) list.push(item)
				})

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}

			if (state.activeName === "appletsHammerMaster") {
				let list = []
				state.list.appletsHammerMaster.active.filter(item => {
					if (item.version.includes(state.edition)) list.push(item)
				})

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}

			if (state.activeName === "appletsHazeDance") {
				let list = []
				state.list.appletsHazeDance.active.filter(item => {
					if (item.version.includes(state.edition)) list.push(item)
				})

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}


			// 装备技能
			if (state.activeName === "appletsEquipment") {
				let list = []
				state.list.appletsEquipment.active.filter(item => {
					if (item.version.includes(state.edition)) list.push(item)
				})

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}

			if (state.activeName === "basicsEquipment") {
				let list = []
				state.list.basicsEquipment.active.filter(item => {
					if (item.version.includes(state.edition)) list.push(item)
				})

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}

			if (state.activeName === "worldh5Equipment") {
				let list = []
				state.list.worldh5Equipment.active.filter(item => {
					if (item.version.includes(state.edition)) list.push(item)
				})

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}

			// 全部数据
			if (state.activeName === "all") {
				let list = []
				// 除宠物外所有主动技能合集
				for (let key in state.list) {
					if (state.list[key].active) {
						state.list[key].active.filter(item => {
							if (!item.identifying.label.includes("宠物") && !item.identifying.label.includes("怪物专属") && item.version.includes(state.edition)) {
								list.push(item)
							}
						})
					}

				}

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}
		},
		// 对技能属性进行特殊处理,只返回宠物相关的技能
		petActiveExhibition(state, getters) {
			// 宠物数据
			if (state.activeName === "petSpecificSkills") {
				let list = []
				state.list.petSpecificSkills.active.filter(item => {
					if (item.version.includes(state.edition)) list.push(item)
				})

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}
			if (state.activeName === "appletsPetEquipment") {
				let list = []
				state.list.appletsPetEquipment.active.filter(item => {
					if (item.version.includes(state.edition)) list.push(item)
				})

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}
			if (state.activeName === "quickPetSkill") {
				let list = []
				state.list.quickPetSkill.active.filter(item => {
					if (item.version.includes(state.edition)) list.push(item)
				})

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}
			if (state.activeName === "smartPetSkill") {
				let list = []
				state.list.smartPetSkill.active.filter(item => {
					if (item.version.includes(state.edition)) list.push(item)
				})

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}
			if (state.activeName === "bravePetSkill") {
				let list = []
				state.list.bravePetSkill.active.filter(item => {
					if (item.version.includes(state.edition)) list.push(item)
				})

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}
			// 全部数据
			if (state.activeName === "all") {
				let list = []
				// 所有宠物相关主动技能合集
				for (let key in state.list) {
					if (state.list[key].active) {
						state.list[key].active.filter(item => {
							if ((item.identifying.label.includes("宠物") || item.skillAttribute.find(items => items.includes("宠物"))) && !item.identifying.label.includes("怪物专属") && item.version.includes(state.edition)) {
								list.push(item)
							}
						})
					}

				}

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}
		},
		// 主动技能选择情况(数据持续累加返回,以达到版本切换效果)
		activeSkillSelection(state) {
			let list = []
			for (const key in state.list) {
				// state.list[key].active.filter(item => item.checked)
				if (state.list[key].active) {
					state.list[key].active.filter(item => {
						if (item.children) {
							item.children.skillLevel.filter(items => {
								// 有些只有一级的技能没有checked属性
								if (items.checked) {
									list.push({ ...items, skillType: 'active' })
								}
							})
						} else {
							if (item.checked) {
								// filter,find返回的都是条件成立的对象,不过filter是以数组的形式返回而find是返回符合条件的元素
								// state.list[key].passive.filter(item => item.checked).length
								// list.push(state.list[key].passive.find(item => item.checked))
								// 这里item就是选出来的最终数据了,不用再做一次筛选
								list.push({ ...item, skillType: 'active' })
							}
						}
					})
				}
			}
			return list
		},
		// 各职业的自动技能
		automaticExhibition(state, getters) {
			if (state.activeName === "warrior") {
				let list = []
				state.list.warrior.automatic.filter(item => {
					if (item.version.includes(state.edition)) list.push(item)
				})

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}

			if (state.activeName === "swordsman") {
				let list = []
				state.list.swordsman.automatic.filter(item => {
					if (item.version.includes(state.edition)) list.push(item)
				})

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}

			if (state.activeName === "xiuZhen") {
				let list = []
				state.list.xiuZhen.automatic.filter(item => {
					if (item.version.includes(state.edition)) list.push(item)
				})

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}

			if (state.activeName === "master") {
				let list = []
				state.list.master.automatic.filter(item => {
					if (item.version.includes(state.edition)) list.push(item)
				})

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}

			if (state.activeName === "sages") {
				let list = []
				state.list.sages.automatic.filter(item => {
					if (item.version.includes(state.edition)) list.push(item)
				})

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}

			if (state.activeName === "Woosung") {
				let list = []
				state.list.Woosung.automatic.filter(item => {
					if (item.version.includes(state.edition)) list.push(item)
				})

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}

			if (state.activeName === "gunKing") {
				let list = []
				state.list.gunKing.automatic.filter(item => {
					if (item.version.includes(state.edition)) list.push(item)
				})

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}

			// 锤师/岚舞技能
			if (state.activeName === "basicsHammerMaster") {
				let list = []
				state.list.basicsHammerMaster.automatic.filter(item => {
					if (item.version.includes(state.edition)) list.push(item)
				})

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}

			if (state.activeName === "basicsHazeDance") {
				let list = []
				state.list.basicsHazeDance.automatic.filter(item => {
					if (item.version.includes(state.edition)) list.push(item)
				})

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}


			if (state.activeName === "appletsHammerMaster") {
				let list = []
				state.list.appletsHammerMaster.automatic.filter(item => {
					if (item.version.includes(state.edition)) list.push(item)
				})

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}

			if (state.activeName === "appletsHazeDance") {
				let list = []
				state.list.appletsHazeDance.automatic.filter(item => {
					if (item.version.includes(state.edition)) list.push(item)
				})

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}


			// 装备技能
			if (state.activeName === "appletsEquipment") {
				let list = []
				state.list.appletsEquipment.automatic.filter(item => {
					if (item.version.includes(state.edition)) list.push(item)
				})

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}

			if (state.activeName === "basicsEquipment") {
				let list = []
				state.list.basicsEquipment.automatic.filter(item => {
					if (item.version.includes(state.edition)) list.push(item)
				})

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}

			if (state.activeName === "worldh5Equipment") {
				let list = []
				state.list.worldh5Equipment.automatic.filter(item => {
					if (item.version.includes(state.edition)) list.push(item)
				})

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}

			// 全部数据
			if (state.activeName === "all") {
				let list = []
				// 除宠物外所有自动技能合集
				for (let key in state.list) {
					if (state.list[key].automatic) {
						state.list[key].automatic.filter(item => {
							if (!item.identifying.label.includes("宠物") && !item.identifying.label.includes("怪物专属") && item.version.includes(state.edition)) {
								list.push(item)
							}
						})
					}

				}
				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}

		},
		// 对技能属性进行特殊处理,只返回宠物相关的技能
		petAutomaticExhibition(state, getters) {
			// 宠物数据
			if (state.activeName === "petSpecificSkills") {
				let list = []
				state.list.petSpecificSkills.automatic.filter(item => {
					if (item.version.includes(state.edition)) list.push(item)
				})

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}
			if (state.activeName === "appletsPetEquipment") {
				let list = []
				state.list.appletsPetEquipment.automatic.filter(item => {
					if (item.version.includes(state.edition)) list.push(item)
				})

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}
			if (state.activeName === "quickPetSkill") {
				let list = []
				state.list.quickPetSkill.automatic.filter(item => {
					if (item.version.includes(state.edition)) list.push(item)
				})

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}
			if (state.activeName === "smartPetSkill") {
				let list = []
				state.list.smartPetSkill.automatic.filter(item => {
					if (item.version.includes(state.edition)) list.push(item)
				})

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}
			if (state.activeName === "bravePetSkill") {
				let list = []
				state.list.bravePetSkill.automatic.filter(item => {
					if (item.version.includes(state.edition)) list.push(item)
				})

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}

			// 全部数据
			if (state.activeName === "all") {
				let list = []
				// 所有宠物相关自动技能合集
				for (let key in state.list) {
					if (state.list[key].active) {
						state.list[key].active.filter(item => {
							if ((item.identifying.label.includes("宠物") || item.skillAttribute.find(items => items.includes("宠物"))) && !item.identifying.label.includes("怪物专属") && item.version.includes(state.edition)) {
								list.push(item)
							}
						})
					}

				}

				// 如果有等级元素被选中则显示等级元素
				getters.skillLevelDisplay(list)
				return list
			}
		},
		// 自动技能选择情况(数据持续累加返回,以达到版本切换效果)
		automaticSkillSelection(state) {
			let list = []
			for (const key in state.list) {
				// state.list[key].automatic.filter(item => item.checked)
				if (state.list[key].automatic) {
					state.list[key].automatic.filter(item => {
						if (item.children) {
							item.children.skillLevel.filter(items => {
								// 有些只有一级的技能没有checked属性
								if (items.checked)
									list.push({ ...items, skillType: 'automatic' })
							})
						} else {
							if (item.checked)
								// filter,find返回的都是条件成立的对象,不过filter是以数组的形式返回而find是返回符合条件的元素
								// state.list[key].passive.filter(item => item.checked).length
								// list.push(state.list[key].passive.find(item => item.checked))
								// 这里item就是选出来的最终数据了,不用再做一次筛选
								list.push({ ...item, skillType: 'automatic' })
						}
					})
				}
			}
			return list
		},
		// 附加属性展示
		attributeDisplay(state) {
			if (state.attributeName === "physical")
				return state.attributes.physical
			if (state.attributeName === "Magic")
				return state.attributes.Magic
			if (state.attributeName === "sundry")
				return state.attributes.sundry
		},
		// 展示选中的数据值
		showTitleAttribute(state) {
			let list = null
			state.title.title.filter(item => {
				if (item.label === state.checktitle[0]) {
					item.children.filter(items => {
						if (items.label === state.checktitle[1]) {
							list = items
						}
					})
				}
			})
			return list
		},
		// 展示选中的宠物天生属性
		showPetBornAttribute(state) {
			let list = null
			state.petBorn.innateAttribute.filter(item => {
				if (item.label === state.checkPetBorn[0]) {
					item.children.filter(items => {
						if (items.label === state.checkPetBorn[1]) {
							list = items
						}
					})
				}
			})
			return list
		},
		showFormationAttribute(state) {
			let list = null
			state.formation.formation.filter(item => {
				if (item.label === state.checkformation) {
					list = item
				}
			})
			return list
		},
		showmedicineAttribute(state) {
			let list = null
			state.medicine.medicine.filter(item => {
				if (item.label === state.checkmedicine) {
					list = item
				}
			})
			return list
		},
		showtransformationcardAttribute(state) {
			let list = null
			state.transformationcard.transformationcard.filter(item => {
				if (item.label === state.checktransformationcard) {
					list = item
				}
			})
			return list
		},
		// 数据展示模块儿
		showdatadisplay(state) {
			if (state.datadisplay === "homologous") {
				let list = []
				// 各职业的技能数据
				for (let key in state.list) {
					// 展示人物技能时不能有宠物和装备技能
					if ((state.skillView === "characterSkills" && key === "appletsEquipment") || (state.skillView === "characterSkills" && key === "basicsEquipment") || (state.skillView === "characterSkills" && key === "worldh5Equipment") || (state.skillView === "characterSkills" && key === "appletsPetEquipment") || (state.skillView === "characterSkills" && key === "petSpecificSkills") || (state.skillView === "characterSkills" && key === "quickPetSkill") || (state.skillView === "characterSkills" && key === "smartPetSkill") || (state.skillView === "characterSkills" && key === "bravePetSkill") || (state.skillView === "characterSkills" && key === "monsterExclusiveSkills")) {
						continue;
					} else if (state.skillView === "petSkills" && key !== "petSpecificSkills" && key !== "quickPetSkill" && key !== "smartPetSkill" && key !== "bravePetSkill") {
						// 展示宠物技能时不能有人物和装备技能
						continue;
					} else if (state.skillView === "equipmentSkills" && key !== "appletsEquipment" && key !== "basicsEquipment" && key !== "worldh5Equipment" && key !== "appletsPetEquipment") {
						// 展示装备技能时不能有宠物和人物技能
						continue;
					} else if (state.skillView === "attributeEnhancement" && key !== "attributeEnhancement") {
						// 只有天赋数据
						continue;
					}

					if (state.list[key].passive || state.list[key].active || state.list[key].automatic) {
						// 所有被动技能
						state.list[key].passive.map(item => {
							if (item.version.includes(state.edition)) {
								list.push(item)
							}
						})
						// 所有主动技能
						state.list[key].active.map(item => {
							if (item.version.includes(state.edition)) {
								list.push(item)
							}
						})
						// 所有自动技能
						state.list[key].automatic.map(item => {
							if (item.version.includes(state.edition)) {
								list.push(item)
							}
						})
					}
				}

				if (state.skillView === "all") {
					// 装备属性
					if (localStorage.getItem("getEquipData")) {
						// 版本对应
						let editions = ""
						switch (state.edition) {
							case "古德":
								editions = "basics"
								break;
							case "小程序":
								editions = "applets"
								break;
							case "世界H5":
								editions = "worldh5"
								break;
						}

						if (state.equipmentList[editions]) {
							state.equipmentList[editions].map(item => {
								item.children.map(items => {
									list.push(items)
								})
							})
						} else {
							// 判断是移动端还是PC端
							// if (equipment()) {
							// 	setTimeout(() => {
							// 		this.$message({
							// 			dangerouslyUseHTMLString: true,
							// 			message: `<span style="font-size:10px;">本次并未展示装备相关数据</span>`,
							// 			type: 'success',
							// 			center: true
							// 		})
							// 	})
							// } else {
							// 	setTimeout(() => {
							// 		this.$message({
							// 			message: `本次并未展示装备相关数据`,
							// 			type: 'success',
							// 			center: true
							// 		})
							// 	})
							// }
							console.log("本次并未展示装备相关数据")
						}
					} else {
						// 判断是移动端还是PC端
						// if (equipment()) {
						// 	setTimeout(() => {
						// 		this.$message({
						// 			dangerouslyUseHTMLString: true,
						// 			message: `<span style="font-size:10px;">您暂未开启装备数据的获取，故本次并未展示</span>`,
						// 			type: 'success',
						// 			center: true
						// 		})
						// 	})
						// } else {
						// 	setTimeout(() => {
						// 		this.$message({
						// 			message: `您暂未开启装备数据的获取，故本次并未展示`,
						// 			type: 'success',
						// 			center: true
						// 		})
						// 	})
						// }
						console.log("您暂未开启装备数据的获取，故本次并未展示")
					}

					// 天赋数据
					let talents = ''
					// 判断版本
					switch (state.edition) {
						case "小程序":
							talents = state.talent[0].appletsTalent
							break;
						case "古德":
							talents = state.talent[1].basicsTalent
							break;
					}
					// 循环遍历取出天赋技能数据
					if (talents) {
						talents.filter(item => {
							item.children.filter(items => {
								items.children.filter(talents => {
									if (talents.value) {
										list.push(talents)
									}
								})
							})
						})
					}

					// 神器数据
					if (state.edition == "小程序") {
						state.artifact.decorate.filter(item => {
							item.children.filter(items => {
								if (items.value) {
									list.push(items);
								}
							})
						})

						state.artifact.stage.filter(item => {
							item.children.filter(items => {
								if (items.value) {
									list.push(items);
								}
							})
						})
					}

					// 称号数据
					state.title.title.filter(item => {
						if (item.children) {
							item.children.filter(items => {
								if (items.version.some(items_ => items_ === state.edition)) {
									list.push(items);
								}
							})
						}
					})

					// 变身卡数据 transformationcard
					state.transformationcard.transformationcard.filter(item => list.push(item))
					// 阵型数据 formation
					state.formation.formation.filter(item => list.push(item))
					// 药物数据 medicine
					state.medicine.medicine.filter(item => list.push(item))
				} else if (state.skillView === "attributeEnhancement") {
					// 天赋数据
					let talents = ''
					// 判断版本
					switch (state.edition) {
						case "小程序":
							talents = state.talent[0].appletsTalent
							break;
						case "古德":
							talents = state.talent[1].basicsTalent
							break;
					}
					// 循环遍历取出天赋技能数据
					if (talents) {
						talents.filter(item => {
							item.children.filter(items => {
								items.children.filter(talents => {
									if (talents.value) {
										list.push(talents)
									}
								})
							})
						})
					}

					// 神器数据
					if (state.edition == "小程序") {
						state.artifact.decorate.filter(item => {
							item.children.filter(items => {
								if (items.value) {
									list.push(items);
								}
							})
						})

						state.artifact.stage.filter(item => {
							item.children.filter(items => {
								if (items.value) {
									list.push(items);
								}
							})
						})
					}
				}

				return list
			}

			// 技能对应
			if (state.datadisplay.includes('-')) {
				let Str = state.datadisplay.lastIndexOf('-')
				// 技能对应的职业
				let occupation = state.datadisplay.substring(0, Str)
				// 各职业的被动,主动,自动技能
				let skill = state.datadisplay.substring(Str + 1, state.datadisplay.length)
				return state.list[occupation][skill]
			}

			// 装备对应 ==>装备图鉴要不要保留还在考虑中
			if (state.datadisplay.includes('_')) {
				let Str = state.datadisplay.lastIndexOf('_')
				// 装备对应版本
				let occupation = state.datadisplay.substring(0, Str)
				// 装备展示模式
				let equipment = state.datadisplay.substring(Str + 1, state.datadisplay.length)

				// 装备属性
				if (localStorage.getItem("getEquipData")) {
					if (state.equipmentList.length !== 0) {
						if (equipment === "all") {
							let list = []
							state.equipmentList[occupation].filter(item => {
								item.children.filter(items => {
									list.push(items)
								})
							})
							return list
						} else {
							let list = []
							state.equipmentList[occupation].filter(item => {
								if (item.value === equipment) {
									item.children.filter(items => {
										list.push(items)
									})
								}
							})
							return list
						}
					} else {
						// 判断是移动端还是PC端
						// if (equipment()) {
						// 	setTimeout(() => {
						// 		this.$message({
						// 			dangerouslyUseHTMLString: true,
						// 			message: `<span style="font-size:10px;">装备数据暂未获取完成</span>`,
						// 			type: 'success',
						// 			center: true
						// 		})
						// 	})
						// } else {
						// 	setTimeout(() => {
						// 		this.$message({
						// 			message: `装备数据暂未获取完成`,
						// 			type: 'success',
						// 			center: true
						// 		})
						// 	})
						// }
						console.log("装备数据暂未获取完成")
					}
				}
			} else {
				// 判断是移动端还是PC端
				// if (equipment()) {
				// 	setTimeout(() => {
				// 		this.$message({
				// 			dangerouslyUseHTMLString: true,
				// 			message: `<span style="font-size:10px;">您暂未开启装备数据的获取</span>`,
				// 			type: 'success',
				// 			center: true
				// 		})
				// 	})
				// } else {
				// 	setTimeout(() => {
				// 		this.$message({
				// 			message: `您暂未开启装备数据的获取`,
				// 			type: 'success',
				// 			center: true
				// 		})
				// 	})
				// }
				console.log("您暂未开启装备数据的获取")
			}

			// 天赋对应
			if (state.datadisplay.includes('/')) {
				// 版本数据
				let str = state.datadisplay.indexOf('/')
				let version = state.datadisplay.substring(0, str)

				// 技能对应的天赋
				let str_1 = state.datadisplay.lastIndexOf('/')
				let occupation = state.datadisplay.substring(str + 1, str_1)
				// 天赋开启等级
				let condition = parseInt(state.datadisplay.substring(str_1 + 1, state.datadisplay.length))
				// 保存天赋数据用于返回
				let list = []
				let talents = ''
				// 判断版本
				switch (version) {
					case "小程序":
						talents = state.talent[0].appletsTalent
						break;
					case "官服":
						talents = state.talent[1].basicsTalent
						break;
				}
				// 循环遍历取出天赋技能数据
				talents.filter(item => {
					if (item.type === occupation) {
						item.children.filter(items => {
							if (items.condition === condition) {
								items.children.filter(talents => {
									if (talents.value) {
										talents.consumablesPrices = {
											title: "天赋材料售价",
											priceList: ['天攻卷轴x1 官服:500黄金，小程序:200黄金', '天攻-异化卷轴x1 官服:1000黄金，小程序:500黄金', '天攻-神化卷轴x1 官服:200黄金，小程序:1000黄金', '天防卷轴x1 官服:500黄金，小程序:200黄金', '天防-铁卷轴x1 官服:1000黄金，小程序:500黄金', '天防-金卷轴x1 官服:2000黄金，小程序:1000黄金', '天通壹型卷轴x1 官服:500黄金，小程序:200黄金', '天通贰型卷轴x1 官服:1000黄金，小程序:500黄金', '天通叁型卷轴x1 官服:2000黄金，小程序:1000黄金']
										}
										list.push(talents)
									}
								})
							}
						})
					}
				})
				return list
			}

			// 神器对应
			if (state.datadisplay.includes('#')) {
				let Str = state.datadisplay.lastIndexOf('#')
				// 神器对应数据
				let occupation = state.datadisplay.substring(0, Str)
				// 神器系统具体指向
				let point = state.datadisplay.substring(Str + 1, state.datadisplay.length)
				// 保存神器数据用于返回
				let list = []
				// 神器装饰
				if (occupation === "decorate") {
					state.artifact.decorate.filter(item => {
						if (item.label === point) {
							item.children.filter(items => {
								if (items.value) {
									list.push(items);
								}
							})
						}
					})
				}
				// 神器阶段等级
				if (occupation === "stage") {
					state.artifact.stage.filter(item => {
						if (item.label === point) {
							item.children.filter(items => {
								if (items.value) {
									list.push(items);
								}
							})
						}
					})
				}
				return list
			}

			// 附魔数据
			if (Object.is(state.datadisplay, 'enchantAttribute')) {
				const list = []

				state.enchantList.map(item => {
					item.children.map(items => list.push(items))
				})

				return list
			}

			// 普通数据直接遍历后返回
			let title = state.title.title.find(item => item.label === state.datadisplay)
			if (title) {
				return title.children
			}

			if (state[state.datadisplay]) return state[state.datadisplay][state.datadisplay] || []
			else return []
		},
		// 技能等级属性展示(数据切换)
		skillLevelDisplay: () => (list) => {
			return list.filter(item => {
				// 如果有等级元素被选中则显示等级元素
				if (item.children) {
					item.children.skillLevel.filter(items => {
						if (items.checked) {
							for (const key in items) {
								item[key] = items[key]
							}
						}
					})
				}
			})
		}
	},
	// 修改源数据
	mutations: {
		// 把数据转存到公共数据源
		initList(state, list) {
			for (const key in list) {
				// 被动技能
				if (list[key].passive) {
					list[key].passive.filter(item => {
						if (item.children) {
							item.children.skillLevel.filter(items => {
								// 将父元素的学习限制、标识、版本限制追加给子元素
								items.special = item.special
								items.identifying = item.identifying
								items.version = item.version
								items.skillScroll = item.skillScroll
							})
						}
					})
				}

				// 主动技能
				if (list[key].active) {
					list[key].active.filter(item => {
						if (!item.attackMode.includes('主动')) {
							item.attackMode += `(主动技能)`
						}
						if (item.children) {
							item.children.skillLevel.filter(items => {
								// 将父元素的学习限制、标识、版本限制追加给子元素
								items.special = item.special
								items.identifying = item.identifying
								items.version = item.version
								if (!items.attackMode.includes('主动')) {
									items.attackMode += `(主动技能)`
								}
							})
						}
					})
				}

				// 自动技能
				if (list[key].automatic) {
					list[key].automatic.filter(item => {
						if (!item.attackMode.includes('自动')) {
							item.attackMode += `(自动技能)`
						}
						if (item.children) {
							item.children.skillLevel.filter(items => {
								// 将父元素的学习限制、标识、版本限制追加给子元素
								items.special = item.special
								items.identifying = item.identifying
								items.version = item.version
								if (!items.attackMode.includes('自动')) {
									items.attackMode += `(自动技能)`
								}
							})
						}
					})
				}
			}
			state.list = list
			// console.log(list);
		},
		initattributes(state, attributes) {
			state.attributes = attributes
			// console.log(attributes);
		},
		inittitle(state, title) {
			state.title = title
			// console.log(title);
		},
		initpetBorn(state, petBorn) {
			state.petBorn = petBorn
			// console.log(petBorn);
		},
		initformation(state, formation) {
			state.formation = formation
			// console.log(formation);
		},
		initmedicine(state, medicine) {
			state.medicine = medicine
			// console.log(medicine);
		},
		inittransformationcard(state, transformationcard) {
			state.transformationcard = transformationcard
			// console.log(transformationcard);
		},
		initequipment(state, equipment) {
			state.equipment = equipment.equipment
			// console.log(state.equipment);
		},
		initpetEquipment(state, petEquipment) {
			state.petEquipment = petEquipment.equipment
		},
		inittalent(state, talent) {
			state.talent = talent.talent
		},
		initartifact(state, artifact) {
			state.artifact = artifact.artifact
		},
		initatlas(state, atlas) {
			state.atlas = atlas
			// console.log(state.atlas);
		},
		initgemProperties(state, gemProperties) {
			state.gemProperties = gemProperties
		},
		initenchantmentAttribute(state, enchantmentAttribute) {
			state.enchantmentAttribute = enchantmentAttribute
		},
		// 初始化天赋/神器数据
		inittalentSelection(state) {
			state.talentSelection = []
		},
		initartifactSelection(state) {
			state.artifactSelection = []
		},
		// 初始化图鉴系统
		initatlasSelection(state) {
			state.atlasSelection = []
		},
		// 更换展示的版本
		setEdition(state, edition) {
			state.edition = edition
		},
		// 修改选中对象
		setactiveName(state, activeName) {
			state.activeName = activeName
		},
		setattributeName(state, attributeName) {
			state.attributeName = attributeName
		},
		// 修改装备获取的状态
		setEquipmentAcquisition(state, equipmentAcquisition) {
			state.equipmentAcquisition = equipmentAcquisition
		},
		// 改变公共数据源中技能数据的状态
		dataStatus(state, skillData) {
			// 将技能时的类型解构出来(被动、主动、自动)然后直接判断
			const { skillType, activeName, name: skillName } = skillData
			if (activeName === "all") {
				for (let key in state.list) {
					if (state.list[key].passive || state.list[key].active || state.list[key].automatic) {
						// skillData.skillType 技能选择时的
						state.list[key][skillType].map(item => {
							if (item.value === skillData.value && skillData.version.includes(item.version[0])) {
								const items = {
									item,
									skillName
								}
								this.commit("skillLevelSelecte", items)
							}
							// name.version.filter(items => {
							// 	if (item.version.some(items_ => items_ == items)) {
							// 		let items = {
							// 			item,
							// 			name
							// 		}
							// 		console.log(items)
							// 		// 技能等级选择
							// 		// this.commit("skillLevelSelecte", items)
							// 		// 		if (item.name === name.name) {
							// 		// 			item.checked = !item.checked
							// 		// 		}
							// 		// 		// 有些只有一级的技能没有checked属性
							// 		// 		if (item.value === name.value && item.children) {
							// 		// 			item.children.skillLevel.filter(items => {
							// 		// 				if (items.name === name.name) {
							// 		// 					items.checked = !items.checked
							// 		// 					// 选中内层元素,外层对应元素一起被选中
							// 		// 					if (item.name === items.name) {
							// 		// 						item.checked = items.checked
							// 		// 					}
							// 		// 				} else {
							// 		// 					items.checked = false
							// 		// 					// 内层元素不被选中,外层对应元素也会失去选中状态
							// 		// 					if (item.name === items.name) {
							// 		// 						item.checked = items.checked
							// 		// 					}
							// 		// 				}
							// 		// 			})
							// 		// 		}
							// 	}
							// })
						})
					}
				}
			} else {
				for (let key in state.list) {
					if (key === activeName) {
						state.list[key][skillType].map(item => {
							if (item.value === skillData.value && skillData.version.includes(item.version[0])) {
								const items = {
									item,
									skillName
								}
								// 技能等级选择
								this.commit("skillLevelSelecte", items)
							}
						})
					}
				}
			}
		},
		skillLevelSelecte(state, items) {
			let { item, skillName } = items
			// 有些只有一级的技能没有checked属性
			if (item.children) {
				item.children.skillLevel.map(items => {
					if (items.name === skillName) {
						items.checked = !items.checked
						// items.skillType = skillType
						// 选中内层元素,外层对应元素一起被选中
						if (item.name === items.name) {
							item.checked = items.checked
						}
					} else {
						items.checked = false
						// 内层元素不被选中,外层对应元素也会失去选中状态
						if (item.name === items.name) {
							item.checked = items.checked
						}
					}
				})
			} else {
				item.checked = !item.checked
			}
		},
		// 调用本地的技能选择
		changeSkill(state, list) {
			this.commit("initdataStatus")
			// 把技能列表中的所有键值遍历出来为版本切换做准备
			let keyList = []
			for (const key in state.list) {
				keyList.push(key)
			}
			// 根据当前版本取出需要的数据
			if (state.edition === "古德") {
				keyList = keyList.filter(item => !(item.includes('applets')) && !(item.includes('worldh5')))
			}
			if (state.edition === "小程序") {
				keyList = keyList.filter(item => !(item.includes('basics')) && !(item.includes('worldh5')))
			}
			if (state.edition === "世界H5") {
				keyList = keyList.filter(item => !(item.includes('basics')) && !(item.includes('applets')))
			}
			// 把本地选中的技能交给vuex代理
			list.map(item => {
				const { skillType } = item
				keyList.map(key => {
					// 通过检测是否有被动技能来过滤掉错误的key键
					if (state.list[key].passive) {
						if (!!skillType) {
							state.list[key][skillType]?.map(items => {
								// 以前录入的数据没有版本信息和value值,我们就给他加上去
								if (!item.value) {
									// item.name === items.value 是因为早期数据是没有等级信息的,所以刚好等于数据的value值
									if (item.name === items.name || item.name === items.value) {
										item.name = items.name
										item.value = items.value
										item.version = items.version
										item.skillScroll = items.skillScroll
									}
								}

								// 传输的技能名称与数据源技能名称相同则同步
								if (item.name === items.name) {
									items.checked = item.checked
								}

								// 有些只有一级的技能没有checked属性
								if (item.value === items.value && items.children) {
									items.children.skillLevel.map(skillData => {
										// 子元素名称与传过来的元素相同则将它的选择状态与传输过来的元素同步
										if (item.name === skillData.name) {
											skillData.checked = item.checked
										}
									})
								}
							})
						} else {
							state.list[key].passive.map(items => {
								// 以前录入的数据没有版本信息和value值,我们就给他加上去
								if (!item.value) {
									// item.name === items.value 是因为早期数据是没有等级信息的,所以刚好等于数据的value值
									if (item.name === items.name || item.name === items.value) {
										item.name = items.name
										item.value = items.value
										item.version = items.version
										item.skillScroll = items.skillScroll
									}
								}

								// 传输的技能名称与数据源技能名称相同则同步
								if (item.name === items.name) {
									items.checked = item.checked
								}

								// 有些只有一级的技能没有checked属性
								if (item.value === items.value && items.children) {
									items.children.skillLevel.map(skillData => {
										// 子元素名称与传过来的元素相同则将它的选择状态与传输过来的元素同步
										if (item.name === skillData.name) {
											skillData.checked = item.checked
										}
									})
								}
							})
							state.list[key].active.map(items => {
								// 以前录入的数据没有版本信息和value值,我们就给他加上去
								if (!item.value) {
									// item.name === items.value 是因为早期数据是没有等级信息的,所以刚好等于数据的value值
									if (item.name === items.name || item.name === items.value) {
										item.name = items.name
										item.value = items.value
										item.version = items.version
										item.skillScroll = items.skillScroll
									}
								}
								// 传输的技能名称与数据源技能名称相同则同步
								if (item.name === items.name) {
									items.checked = item.checked
								}
								// 有些只有一级的技能没有checked属性
								if (item.value === items.value && items.children) {
									items.children.skillLevel.map(skillData => {
										// 子元素名称与传过来的元素相同则将它的选择状态与传输过来的元素同步
										if (item.name === skillData.name) {
											skillData.checked = item.checked
										}
									})
								}
							})
							state.list[key].automatic.map(items => {
								// 以前录入的数据没有版本信息和value值,我们就给他加上去
								if (!item.value) {
									// item.name === items.value 是因为早期数据是没有等级信息的,所以刚好等于数据的value值
									if (item.name === items.name || item.name === items.value) {
										item.name = items.name
										item.value = items.value
										item.version = items.version
										item.skillScroll = items.skillScroll
									}
								}
								// 传输的技能名称与数据源技能名称相同则同步
								if (item.name === items.name) {
									items.checked = item.checked
								}
								// 有些只有一级的技能没有checked属性
								if (item.value === items.value && items.children) {
									items.children.skillLevel.map(skillData => {
										// 子元素名称与传过来的元素相同则将它的选择状态与传输过来的元素同步
										if (item.name === skillData.name) {
											skillData.checked = item.checked
										}
									})
								}
							})
						}
					}
				})
			})
		},
		// 初始化公共数据源
		initdataStatus(state) {
			for (const key in state.list) {
				// 阻止字符串通过去搞破坏
				if ((typeof state.list[key]) !== "string") {
					state.list[key].passive.map(item => {
						// 有些只有一级的技能没有checked属性
						if (item.children) {
							item.children.skillLevel.map(items => {
								items.checked = false
							})
						}
						// 外层数据选中状态也需要处理
						item.checked = false
					})
					state.list[key].active.map(item => {
						// 有些只有一级的技能没有checked属性
						if (item.children) {
							item.children.skillLevel.map(items => {
								items.checked = false
							})
						}
						// 外层数据选中状态也需要处理
						item.checked = false
					})
					state.list[key].automatic.map(item => {
						// 有些只有一级的技能没有checked属性
						if (item.children) {
							item.children.skillLevel.map(items => {
								items.checked = false
							})
						}
						// 外层数据选中状态也需要处理
						item.checked = false
					})
				}
			}
		},
		// 修改选中的称号
		setchecktitle(state, checked) {
			state.checktitle = checked
		},
		// 修改选中的宠物天生属性
		setcheckPetBorn(state, checked) {
			state.checkPetBorn = checked
		},
		// 修改选中的阵型
		setcheckformation(state, checked) {
			state.checkformation = checked
		},
		// 修改使用的药物
		setcheckmedicine(state, checked) {
			state.checkmedicine = checked
		},
		// 修改使用的变身卡
		setchecktransformationcard(state, checked) {
			state.checktransformationcard = checked
		},
		setdatadisplay(state, select) {
			state.datadisplay = select
		},
		setSkillView(state, skillView) {
			state.skillView = skillView
		},
		// 修改选中的天赋数据
		settalentSelection(state, talentSelection) {
			// 先用some方法检查其中是否有该属性,如果有则用find方法把它找出来并修改它的值,如果没有就添加
			if (state.talentSelection.some(item => item.name === talentSelection.talent)) {
				state.talentSelection.find(item => item.name === talentSelection.talent).
					data = talentSelection.item
			} else {
				state.talentSelection.push({
					name: talentSelection.talent,
					data: talentSelection.item
				})
			}
		},
		// 修改选中的神器数据
		setartifactSelection(state, artifactSelection) {
			// 先用some方法检查其中是否有该属性,如果有则用find方法把它找出来并修改它的值,如果没有就添加
			if (state.artifactSelection.some(item => item.name === artifactSelection.artifact)) {
				state.artifactSelection.find(item => item.name === artifactSelection.artifact).
					data = artifactSelection.item
			} else {
				state.artifactSelection.push({
					name: artifactSelection.artifact,
					data: artifactSelection.item
				})
			}
			// console.log(state.artifactSelection);
		},
		// 修改选中的图鉴数据
		setatlasSelection(state, atlasSelection) {
			if (state.atlasSelection.some(item => item.name === atlasSelection.talent)) {
				state.atlasSelection.find(item => item.name === atlasSelection.talent).
					data = atlasSelection.item
			} else {
				state.atlasSelection.push({
					name: atlasSelection.talent,
					data: atlasSelection.item
				})
			}
		},
		// 传输数据名称
		settransferDataName(state, transferDataName) {
			state.transferDataName = transferDataName
		},
		// 玩法推荐数据列表
		initrecommendList(state, recommendList) {
			state.recommendList = recommendList
		},
		// 原创文章数据列表
		initarticleList(state, articleList) {
			state.articleList = articleList
		},
		// 改变公共数据源工具状态
		toolsStatus(state, name) {
			// console.log(state.toolList);
			state.toolList.filter(item => {
				if (item.name === name) {
					item.checked = !item.checked
				}
			})
		},
		// 本地有的工具状态为选中
		localTools(state, name) {
			state.toolList.filter(item => {
				if (item.name === name) {
					item.checked = true
				}
			})
		},
		// 初始化副本列表
		initgamesList(state, gamesList) {
			state.gamesList = gamesList
		},
		// 初始化怪物列表
		initmonsterList(state, monsterList) {
			state.monsterList = monsterList
		},
		// 初始化装备列表
		initequipmentList(state, equipmentList) {
			for (const key in equipmentList) {
				equipmentList[key].map(item => {
					item.children.map(items => {
						if (item.value === 'armsAtlas' && items.value && (!items.name.includes('武器'))) {
							items.name += `(武器)`
						}
						if (item.value === 'armorAtlas' && items.value && (!items.name.includes('防具'))) {
							items.name += `(防具)`
						}
						if (item.value === 'decorationAtlas' && items.value && (!items.name.includes('装饰'))) {
							items.name += `(装饰)`
						}
						if (item.value === 'mountAtlas' && items.value && (!items.name.includes('坐骑'))) {
							items.name += `(坐骑)`
						}
						if (item.value === 'petArmorAtlas' && items.value && (!items.name.includes('宠物装备'))) {
							items.name += `(宠物装备)`
						}
					})
				})
			}
			state.equipmentList = equipmentList
		},
		// 初始化附魔数据
		initenchantList(state, enchantList) {
			state.enchantList = enchantList
		},
		// 修改推荐方式
		changeRecommendation(state, recommendation) {
			state.recommendationMethod = recommendation
		}
	},
	// 异步方法,一般用于发请求
	actions: {
		// 要先调用此方法才会触发函数发起请求
		// 请求技能数据
		async getList(context) {
			// 在数据获取前,数据还未到达时使用本地已有数据
			context.dispatch('dataCalling', { name: "skillList", fun: "initList" })
			const { data: res } = await axios.get("/vocationalSkills.json")
			// 通过commit调用mutations里的方法修改store中的全局数据
			context.commit('initList', res)
			// 更新本地数据
			context.dispatch('dataUpdates', { name: "skillList", datas: res })
		},
		// 请求属性选择数据
		async getattribute(context) {
			// 在数据获取前,数据还未到达时使用本地已有数据
			context.dispatch('dataCalling', { name: "attribute", fun: "initattributes" })
			const { data: res } = await axios.get("/attributeOptions.json")
			// 通过commit调用mutations里的方法修改store中的全局数据
			context.commit('initattributes', res)
			// 更新本地数据
			context.dispatch('dataUpdates', { name: "attribute", datas: res })
		},
		// 请求称号数据
		async gettitle(context) {
			// 在数据获取前,数据还未到达时使用本地已有数据
			context.dispatch('dataCalling', { name: "title", fun: "inittitle" })
			const { data: res } = await axios.get("/title.json")
			// 通过commit调用mutations里的方法修改store中的全局数据
			context.commit('inittitle', res)
			// 更新本地数据
			context.dispatch('dataUpdates', { name: "title", datas: res })
		},
		// 请求宠物天生数据
		async getpetBorn(context) {
			// 在数据获取前,数据还未到达时使用本地已有数据
			context.dispatch('dataCalling', { name: "petBorn", fun: "initpetBorn" })
			const { data: res } = await axios.get("/petInnateAttribute.json")
			// 通过commit调用mutations里的方法修改store中的全局数据
			context.commit('initpetBorn', res)
			// 更新本地数据
			context.dispatch('dataUpdates', { name: "petBorn", datas: res })
		},
		// 请求阵型数据
		async getformation(context) {
			// 在数据获取前,数据还未到达时使用本地已有数据
			context.dispatch('dataCalling', { name: "formation", fun: "initformation" })
			const { data: res } = await axios.get("/formation.json")
			// 通过commit调用mutations里的方法修改store中的全局数据
			context.commit('initformation', res)
			// 更新本地数据
			context.dispatch('dataUpdates', { name: "formation", datas: res })
		},
		// 请求药物数据
		async getmedicine(context) {
			// 在数据获取前,数据还未到达时使用本地已有数据
			context.dispatch('dataCalling', { name: "medicine", fun: "initmedicine" })
			const { data: res } = await axios.get("/medicine.json")
			// 通过commit调用mutations里的方法修改store中的全局数据
			context.commit('initmedicine', res)
			// 更新本地数据
			context.dispatch('dataUpdates', { name: "medicine", datas: res })
		},
		// 请求变身卡数据
		async gettransformationcard(context) {
			// 在数据获取前,数据还未到达时使用本地已有数据
			context.dispatch('dataCalling', { name: "transformationcard", fun: "inittransformationcard" })
			const { data: res } = await axios.get("/transformationcard.json")
			// 通过commit调用mutations里的方法修改store中的全局数据
			context.commit('inittransformationcard', res)
			// 更新本地数据
			context.dispatch('dataUpdates', { name: "transformationcard", datas: res })
		},
		// 获取装备的属性数据
		async getequipment(context) {
			// 在数据获取前,数据还未到达时使用本地已有数据
			context.dispatch('dataCalling', { name: "equipment", fun: "initequipment" })
			const { data: res } = await axios.get("/equipment.json")
			context.commit("initequipment", res)
			// 更新本地数据
			context.dispatch('dataUpdates', { name: "equipment", datas: res })
		},
		// 获取宠物装备的属性数据
		async getpetEquipment(context) {
			// 在数据获取前,数据还未到达时使用本地已有数据
			context.dispatch('dataCalling', { name: "petEquipment", fun: "initpetEquipment" })
			const { data: res } = await axios.get("/petEquipment.json")
			context.commit("initpetEquipment", res)
			// 更新本地数据
			context.dispatch('dataUpdates', { name: "petEquipment", datas: res })
		},
		// 获取天赋系统数据
		async gettalent(context) {
			// 在数据获取前,数据还未到达时使用本地已有数据
			context.dispatch('dataCalling', { name: "talent", fun: "inittalent" })
			const { data: res } = await axios.get("/talent.json")
			context.commit("inittalent", res)
			// 更新本地数据
			context.dispatch('dataUpdates', { name: "talent", datas: res })
		},
		// 获取神器系统数据
		async getartifact(context) {
			// 在数据获取前,数据还未到达时使用本地已有数据
			context.dispatch('dataCalling', { name: "artifact", fun: "initartifact" })
			const { data: res } = await axios.get("/artifact.json")
			context.commit("initartifact", res)
			// 更新本地数据
			context.dispatch('dataUpdates', { name: "artifact", datas: res })
		},
		// 获取图鉴系统数据
		async getatlas(context) {
			// 在数据获取前,数据还未到达时使用本地已有数据
			context.dispatch('dataCalling', { name: "atlas", fun: "initatlas" })
			const { data: res } = await axios.get("/illustratedHandbook.json")
			context.commit("initatlas", res)
			// 更新本地数据
			context.dispatch('dataUpdates', { name: "atlas", datas: res })
		},
		// 获取宝石数据
		async getgemProperties(context) {
			// 在数据获取前,数据还未到达时使用本地已有数据
			context.dispatch('dataCalling', { name: "gemProperties", fun: "initgemProperties" })
			const { data: res } = await axios.get("/gemProperties.json")
			context.commit("initgemProperties", res.gemProperties)
			// 更新本地数据
			context.dispatch('dataUpdates', { name: "gemProperties", datas: res.gemProperties })
		},
		// 获取附魔属性
		async getenchantmentAttribute(context) {
			// 在数据获取前,数据还未到达时使用本地已有数据
			context.dispatch('dataCalling', { name: "enchantmentAttribute", fun: "initenchantmentAttribute" })
			const { data: res } = await axios.get("/enchantmentAttribute.json")
			context.commit("initenchantmentAttribute", res.enchantmentAttribute)
			// 更新本地数据
			context.dispatch('dataUpdates', { name: "enchantmentAttribute", datas: res.enchantmentAttribute })
		},
		// 获取传输数据名称
		async gettransferDataName(context) {
			// 在数据获取前,数据还未到达时使用本地已有数据
			context.dispatch('dataCalling', { name: "transferDataName", fun: "settransferDataName" })
			const { data: res } = await axios.get("/transferDataName.json")
			context.commit("settransferDataName", res.transferDataName)
			// 更新本地数据
			context.dispatch('dataUpdates', { name: "transferDataName", datas: res.transferDataName })
		},
		// 获取玩法推荐数据
		async getrecommendList(context) {
			// 在数据获取前,数据还未到达时使用本地已有数据
			context.dispatch('dataCalling', { name: "recommendList", fun: "initrecommendList" })
			const { data: res } = await axios.get("/playerPlay.json")
			context.commit("initrecommendList", res.posts)
			// 更新本地数据
			context.dispatch('dataUpdates', { name: "recommendList", datas: res.posts })
		},
		// 获取原创文章数据
		async getarticleList(context) {
			// 在数据获取前,数据还未到达时使用本地已有数据
			context.dispatch('dataCalling', { name: "articleList", fun: "initarticleList" })
			const { data: res } = await axios.get("/playerPlay.json")
			context.commit("initarticleList", res.article)
			// 更新本地数据
			context.dispatch('dataUpdates', { name: "articleList", datas: res.article })
		},
		// 获取副本列表
		async getgamesList(context) {
			// 在数据获取前,数据还未到达时使用本地已有数据
			context.dispatch('dataCalling', { name: "gamesList", fun: "initgamesList" })
			const { data: res } = await axios.get("/gamesIntroduction.json")
			context.commit("initgamesList", res.Games)
			// 更新本地数据
			context.dispatch('dataUpdates', { name: "gamesList", datas: res.Games })
		},
		// 获取怪物数据
		async getmonsterList(context) {
			// 在数据获取前,数据还未到达时使用本地已有数据
			context.dispatch('dataCalling', { name: "monsterList", fun: "initmonsterList" })
			const { data: res } = await axios.get("/monsterInformation.json")
			context.commit("initmonsterList", res.monsterInformation)
			// 更新本地数据
			context.dispatch('dataUpdates', { name: "monsterList", datas: res.monsterInformation })
		},
		// 获取装备图鉴数据
		async getequipList(context) {
			// 在数据获取前,数据还未到达时使用本地已有数据
			context.dispatch('dataCalling', { name: "equipList", fun: "initequipmentList" })
			const { data: res } = await axios.get("/equipmentAtlas.json")
			context.commit("initequipmentList", res.equipmentAtlas)
			// 更新本地数据
			context.dispatch('dataUpdates', { name: "equipList", datas: res.equipmentAtlas })
		},
		// 获取附魔数据
		async getEnchant(context) {
			// 在数据获取前,数据还未到达时使用本地已有数据
			context.dispatch('dataCalling', { name: "enchantList", fun: "initenchantList" })
			const { data: res } = await axios.get("/enchantAttribute.json")
			context.commit("initenchantList", res.enchantAttribute)
			// 更新本地数据
			context.dispatch('dataUpdates', { name: "enchantList", datas: res.enchantAttribute })
		},
		// 创建数据表
		dataCalling(context, data) {
			let { name, fun } = data
			keys(customStore).then((keys) => {
				if (keys.some(item => item == name)) {
					get(name, customStore).then((val) => {
						if (val) {
							context.commit(fun, val)
						}
					});
				} else {
					set(name, "", customStore)
						.then(() => console.log('It worked!'))
						.catch((err) => console.log('It failed!', err));
				}
			});
		},
		// 更新数据
		dataUpdates(context, data) {
			let { name, datas } = data
			update(name, (val) => datas, customStore)
		}
	},
	modules: {}
})

const equipment = () => {
	const flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
	return flag
}