exports.install = function (Vue) {
  Vue.prototype.antecedent = function (data, hit, order, action, mode) {
    // 没传的数据就默认为 0
    if (!data) {
      data = 0
    }
    if (!hit) {
      hit = 0
    }

    // list数组表示要遍历的对象
    let list = []
    // 当前技能叠加的值
    let superposition = 0
    let roundsuperposition = 0
    // 魔化叠加数组
    let enchantment = []
    // 战斗叠加的值
    let battles = 0
    // 命中叠加的数组
    let hitList = []
    // 魔化过期的的数据值
    let overdue = 0
    // 当前回合命中叠加的值
    let hitsuperposition = 0
    // 命中过期的数据值
    let hitsoverdue = 0
    // 命中上限
    let hitCap = 32767
    // 攻击力上限
    let upperLimit = 1000000
    // 记录保留第一次到达百万的数据值
    let record = true
    let upper = 0
    let copy = 0
    // 循环遍历计算数据
    for (let i = 1; i <= 30; i++) {
      // 数据过期
      overdue = 0
      superposition = 0
      roundsuperposition = 0
      // 命中过期数据值
      // 战斗效果持续到第四回合结束,但由于值在结束后扣除这里就直接在第五回合进行扣除
      if (i > 4) {
        hit -= hitsoverdue
      }
      if (i >= 4) {
        hitsoverdue = hitList[i - 4]
      }
      // 魔化效果持续到第五回合结束,但由于值在结束后扣除这里就直接在第六回合进行扣除
      if (i > 5) {
        data -= enchantment[i - 6]
      }
      if (i >= 5) {
        overdue = enchantment[i - 5]
      }
      // 数据叠加--order为真说明是魔化先行,反之战斗先行
      if (order) {
        // 备份当前数据
        copy = data
        // 接受魔化叠加
        data = parseInt(data * 1.24)
        // 最高100w
        data = data > upperLimit ? upperLimit : data
        // 当前魔化叠加的值
        superposition = data - copy
        // 将当前魔化叠加的值放入数组中进行保存
        enchantment.push(superposition)
        roundsuperposition += superposition
        // 是否为半个战斗
        if (!action) {
          if (i % 2 === 1) {
            overdue += battles
            // 战斗叠加的前一秒上回合战斗叠加的数据值过期
            // data -= battles
            // 备份当前数据(因为后面基础要扣除战斗叠加的值所以这里就先直接扣除了)
            copy = data - battles
            // 接受战斗叠加
            data = parseInt(data * 1.4)
            // 战斗在上一个的基础上叠加
            data -= battles
            // 最高100w
            data = data > upperLimit ? upperLimit : data
            // 用当前数据值减去之前数据值为战斗叠加的数据值
            battles = data - copy
            roundsuperposition += battles
          }
        } else {
          overdue += battles
          // 战斗叠加的前一秒上回合战斗叠加的数据值过期
          // data -= battles
          // 备份当前数据(因为后面基础要扣除战斗叠加的值所以这里就先直接扣除了)
          copy = data - battles
          // 接受战斗叠加
          data = parseInt(data * 1.4)
          // 战斗在上一个的基础上叠加
          data -= battles
          // 最高100w
          data = data > upperLimit ? upperLimit : data
          // 用当前数据值减去之前数据值为战斗叠加的数据值
          battles = data - copy
          roundsuperposition += battles
        }
        // 第一次到达百万时改变触发记录时刻
        if (data >= upperLimit) {
          if (record) upper = i
          record = false
        }
        // console.log(battles);
        // 魔化分界线
      } else {
        // 是否为半个战斗
        if (!action) {
          if (i % 2 === 1) {
            overdue += battles
            // 战斗叠加的前一秒上回合战斗叠加的数据值过期
            // data -= battles
            // 备份当前数据(因为后面基础要扣除战斗叠加的值所以这里就先直接扣除了)
            copy = data - battles
            // 接受战斗叠加
            data = parseInt(data * 1.4)
            // 战斗在上一个的基础上叠加
            data -= battles
            // 最高100w
            data = data > upperLimit ? upperLimit : data
            // 用当前数据值减去之前数据值为战斗叠加的数据值
            battles = data - copy
            roundsuperposition += battles
          }
        } else {
          overdue += battles
          // 战斗叠加的前一秒上回合战斗叠加的数据值过期
          // data -= battles
          // 备份当前数据(因为后面基础要扣除战斗叠加的值所以这里就先直接扣除了)
          copy = data - battles
          // 接受战斗叠加
          data = parseInt(data * 1.4)
          // 战斗在上一个的基础上叠加
          data -= battles
          // 最高100w
          data = data > upperLimit ? upperLimit : data
          // 用当前数据值减去之前数据值为战斗叠加的数据值
          battles = data - copy
          roundsuperposition += battles
        }
        // 第一次到达百万时改变触发记录时刻
        if (data >= upperLimit) {
          if (record) upper = i
          record = false
        }
        // 重新备份当前数据
        copy = data
        // 接受魔化叠加
        data = parseInt(data * 1.24)
        // 第一次到达百万时改变触发记录时刻
        if (data >= upperLimit) {
          if (record) upper = i
          record = false
        }
        // 最高100w
        data = data > upperLimit ? upperLimit : data
        // 当前魔化叠加的值
        superposition = data - copy
        // 将当前魔化叠加的值放入数组中进行保存
        enchantment.push(superposition)
        roundsuperposition += superposition
        // console.log(battles);
      }
      // 命中的叠加不受魔化战斗的顺序影响, 只受到战斗个数的影响
      // 备份当前命中数据值
      copy = hit
      switch (action) {
        case 0:
          if (i % 2 === 1) {
            // 接受战斗的命中叠加
            hit = parseInt(hit * 1.26)
            // 命中上限32767
            hit = hit > hitCap ? hitCap : hit
            // 当前命中叠加的值
            hitsuperposition = hit - copy
            // 将当前命中叠加的值放入数组中进行保存
            hitList.push(hitsuperposition)
          } else {
            hitList.push(0)
          }
          break
        case 1:
          // 接受战斗的命中叠加
          hit = parseInt(hit * 1.26)
          // 命中上限32767
          hit = hit > hitCap ? hitCap : hit
          // 当前命中叠加的值
          hitsuperposition = hit - copy
          // 将当前命中叠加的值放入数组中进行保存
          hitList.push(hitsuperposition)
          break
        case 2:
          // 接受战斗的命中叠加
          hit = parseInt(hit * 1.26)
          if (i % 2 == 0) {
            // 接受战斗的命中叠加
            hit = parseInt(hit * 1.26)
          }
          // 命中上限32767
          hit = hit > hitCap ? hitCap : hit
          // 当前命中叠加的值
          hitsuperposition = hit - copy
          // 将当前命中叠加的值放入数组中进行保存
          hitList.push(hitsuperposition)
          break
        case 3:
          // 接受战斗的命中叠加
          hit = parseInt(hit * 1.26 * 1.26)
          // 命中上限32767
          hit = hit > hitCap ? hitCap : hit
          // 当前命中叠加的值
          hitsuperposition = hit - copy
          // 将当前命中叠加的值放入数组中进行保存
          hitList.push(hitsuperposition)
          break
      }
      switch (mode) {
        case 0:
          // 将数据追加展示
          list.push({
            round: `第【${i}】回合`,
            hit: `命中值为:${hit}`,
            data: `劈砍/穿刺值为:${data}`,
            hits: hit,
            datas: data
          })
          // 到达百万跳出当前循环
          // if (data >= upperLimit) break
          break
        case 1:
          // 将数据追加展示
          list.push({
            round: `第【${i}】回合`,
            hit: `命中值为:${hit}`,
            data: `劈砍/穿刺值为:${data}`,
            hits: hit,
            datas: data,
            overdue: `即将过期的劈砍/穿刺值为:${overdue}`,
            superposition: `叠加的劈砍/穿刺值为:${roundsuperposition}`,

          })
          // 到达百万跳出当前循环
          // if (data >= upperLimit) break
          break
        case 2:
          // 将数据追加展示
          list.push({
            round: `第【${i}】回合`,
            hit: `命中值为:${hit}`,
            data: `劈砍/穿刺值为:${data}`,
            hits: hit,
            datas: data,
            overdue: `即将过期的命中值为:${hitsoverdue}`,
            superposition: `叠加的命中值为:${hitsuperposition}`
          })
          // 到达百万跳出当前循环
          // if (data >= upperLimit) break
          break
      }
    }
    // console.log(hits);
    return {
      list,
      upper
    }
  }
}