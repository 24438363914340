exports.install = function (Vue) {
  Vue.prototype.energyWithdrawal = function (deduction, skillAttribute, characteristic) {
    // 本页面使用的都是对象深拷贝
    // 将回合进行格式化
    skillAttribute = JSON.parse(JSON.stringify(skillAttribute)).filter(item => {
      if (item.controlTurnRelease) {
        return item.releaseRound = Array.from(new Set(item.releaseRound.replaceAll("，", ",").split(",").filter(item => item != '')))
      } else {
        return item
      }
    })

    // 蓝量总值
    // console.log(deduction);
    // 扣篮的技能
    // console.log(skillAttribute);
    // 副本是否有回合回复效果
    // console.log(characteristic);

    // 数据叠加保存的数组
    let list = []

    // 备份最大蓝量值
    let energy = deduction
    let energyArry = []
    // 当前回合蓝量初始值
    let currentData = []

    // 备份数据,后面通过备份的数据计算
    let backupData = JSON.parse(JSON.stringify(skillAttribute)).filter(item => !item.controlTurnRelease || (Array.isArray(item.releaseRound) && item.releaseRound.some(items => Object.is(parseInt(items), 1))))
    // 对传过来的数据先做个备份
    let copySkillAttribute = JSON.parse(JSON.stringify(skillAttribute))
    // 通过json转义达到对象深拷贝的效果==>不是引用地址值而是拿到具体数据
    // skillAttribute.filter(item => backupData.push(JSON.parse(JSON.stringify(item))))
    // skillAttribute.filter(item => copySkillAttribute.push(JSON.parse(JSON.stringify(item))))

    // 持续回合扣除百分比
    let continuousDeduction = 0
    // 持续回合扣除的基础值
    let continuousAdditional = 0
    // 持续回合扣除的具体数据
    let roundDeduction = 0
    let roundDeductionArry = []
    // 扣篮技能数组
    let dunkSkillArry = []
    let dunkSkills = []

    for (let i = 1; i <= 30; i++) {
      // 数据初始化
      roundDeduction = 0

      // 副本回合回复
      if (characteristic) {
        deduction = parseInt(deduction * 1.005)
      }
      // 对最大蓝量进行规范
      deduction = energy > deduction ? deduction : energy

      // 当前回合初始值
      currentData.push(deduction)

      // 从第二回合开始,向传递过来的属性值里面填充备份的数据==>模拟打副本时技能效果叠加
      if (i > 1) {
        copySkillAttribute.map(item => {
          // 开启回合控制的技能只有在指定回合才会追加
          if (item.controlTurnRelease && item.releaseRound.some(items => Object.is(parseInt(items), i))) {
            backupData.push(JSON.parse(JSON.stringify(item)))
          }
          // 单开模式每回合增加数据
          if (!item.mode && !item.controlTurnRelease) {
            backupData.push(JSON.parse(JSON.stringify(item)))
          }
          // 混开模式单回合增加数据
          if (item.mode) {
            if ((!item.doubleRound && i % 2 !== 0) || (item.doubleRound && i % 2 === 0)) {
              backupData.push(JSON.parse(JSON.stringify(item)))
            } else {
              // 混开模式在双回合不会有数据填充,因此,扣除值也应该为0
              // 但是为了防止扣除值数据被打乱,这里填充一个扣除0%的数据值,持续一回合不会影响数据
              backupData.push({
                mode: true,
                additional: 0,
                round: 1,
                value: 0,
                controlTurnRelease: false
              })
            }
          }
        })
      }

      // 持续回合扣除的值continuousDeduction
      roundDeduction = parseInt(energy * continuousDeduction) + continuousAdditional
      roundDeduction = deduction > roundDeduction ? roundDeduction : deduction
      deduction -= roundDeduction
      roundDeductionArry.push(roundDeduction)

      // 重置持续回合扣除
      continuousDeduction = 0
      // 重置持续回合扣除的基础值
      continuousAdditional = 0

      // 遍历传递过来的技能属性值
      if (backupData.length) {
        backupData.map((item) => {
          // 属性过期回合分配
          if (!item.overdue) {
            if (item.controlTurnRelease) {
              item.releaseRound.map(items => {
                if (Object.is(i, Number.parseInt(items))) {
                  item.overdue = parseInt(item.round) + i - 1
                  let deductionData = parseInt((item.value / 100) * energy) + (item.additional || 0)
                  deductionData = deduction > deductionData ? deductionData : deduction
                  deduction -= deductionData
                  dunkSkillArry.push({ deductionData, designatedRound: true, round: i })
                }
              })
            } else {
              item.overdue = parseInt(item.round) + i - 1
              // 单回合数据使用
              if (item.round === 1) {
                // (item.additional || 0) 防止用户误删技能基础属性扣除值
                let deductionData = parseInt((item.value / 100) * energy) + (item.additional || 0)
                deductionData = deduction > deductionData ? deductionData : deduction
                deduction -= deductionData
                dunkSkillArry.push({ deductionData, designatedRound: false, round: i })
              } else {
                // 单开模式的数据
                if (!item.mode) {
                  let deductionData = parseInt((item.value / 100) * energy) + (item.additional || 0)
                  deductionData = deduction > deductionData ? deductionData : deduction
                  deduction -= deductionData
                  dunkSkillArry.push({ deductionData, designatedRound: false, round: i })
                } else
                  // 混开模式的数据
                  if (item.mode && (!item.doubleRound && i % 2 !== 0) || (item.doubleRound && i % 2 === 0)) {
                    let deductionData = parseInt((item.value / 100) * energy) + (item.additional || 0)
                    deductionData = deduction > deductionData ? deductionData : deduction
                    deduction -= deductionData
                    dunkSkillArry.push({ deductionData, designatedRound: false, round: i })
                  } else {
                    dunkSkillArry.push({ deductionData: 0, designatedRound: false, round: i })
                  }
              }
            }

            // 持续回合的扣除值
            // 单开模式的数据
            if (!item.mode && i < item.overdue) {
              continuousDeduction = parseFloat(((continuousDeduction * 100 + item.value) / 100)).toFixed(3)
              // 持续回合扣除的基础值
              continuousAdditional += (item.additional || 0)
            }

            // 混开模式的数据
            if (item.mode && (!item.doubleRound && i % 2 !== 0) || (item.doubleRound && i % 2 === 0) && i < item.overdue) {
              continuousDeduction = parseFloat(((continuousDeduction * 100 + item.value) / 100)).toFixed(3)
              // 持续回合扣除的基础值
              continuousAdditional += (item.additional || 0)
            }
          }
        })
      }
      // 将回合剩余值保留到数组中 
      energyArry.push(deduction)

      if (deduction <= 0) {
        if (dunkSkillArry.length) {
          for (let i = 0, len = dunkSkillArry[dunkSkillArry.length - 1].round; i <= len; i++) {
            let index = 1
            dunkSkillArry.map(item => {
              if (Object.is(item.round, i)) {
                if (!item.designatedRound) {
                  dunkSkills.push({
                    massage: `扣篮技能${index}扣除的蓝量为:${item.deductionData}`,
                    round: item.round
                  })
                  index++
                } else {
                  dunkSkills.push({
                    massage: `回合控制扣篮技能扣除蓝量为:${item.deductionData}`,
                    round: item.round
                  })
                }
              }
            })
            // let index = 1, arryLength = skillAttribute.length
            // for (let j = 0, len = dunkSkillArry.length; j < len; j++) {
            //   dunkSkills.push(`扣篮技能${index}扣除的蓝量为:${dunkSkillArry[j]}`)
            //   index++
            //   if (index > arryLength) index = 1
            // }
          }
        }

        dunkSkills = againGroup(dunkSkills)
        for (let j = 1; j <= i; j++) {
          list.push({
            round: `第【${j}】回合`,
            currentData: `当前回合初始值为:${currentData[j - 1]}`,
            continued: `持续回合扣除值:${roundDeductionArry[j - 1] ?? 0}`,
            dunkSkills: dunkSkills[j - 1],
            dataObject: `回合结束剩余值为:${energyArry[j - 1]}`
          })
          // }
        }
        break;
      }
      if (i === 30 && deduction > 0) {
        alert("目前的技能配比无法扣完所有的蓝量，将不会进行数据展示")
        return false
      }
    }

    return list

    // 切割数组
    function againGroup(data) {
      const result = [];
      for (let i = 1, len = data[data.length - 1]?.round; i <= len; i++) {
        const count = data.filter(item => item.round === i).length
        if (count) {
          const temp = []
          data.map(item => {
            if (item.round === i) {
              temp.push(item.massage)
              if (temp.length === count) {
                result.push(temp)
              }
            }
          })
        } else {
          result.push([])
        }
      }
      return result;
    }
  }
}