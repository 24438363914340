exports.install = function (Vue) {
  Vue.prototype.gradeSuppression = function (keyValue, dataValue, targetLevelL) {
    let { label, value } = keyValue
    // 初始数据
    let data = ""

    // 格挡: block, 洞察: insight, 反击: counterAttack, 魔法反馈: magicFeedback *7%
    let arr = ["block", "insight", "counterAttack", "magicFeedback"]
    arr.some(item => {
      // 格挡：(格挡 / 攻击方等级 * 0.07) * 100) / 100
      if (item === value) {
        data = parseInt((dataValue / targetLevelL * 0.07) * 100) / 100
      }
    })
    // 致命: (致命 / 攻击方等级 * 0.15) * 100) / 100
    if (value === "deadly") {
      data = parseInt((dataValue / targetLevelL * 0.15) * 100) / 100
    }
    // 魔命(魔法命中): (魔命 / 攻击方等级 * 0.1) * 100 / 100
    if (value === "magicHit") {
      data = parseInt((dataValue / targetLevelL * 0.1) * 100) / 100
    }
    // 防御力(三防): defense 防御力 / (攻击方等级+攻击方传奇等级 + 12) *0.8
    if (value === "defense") {
      data = parseInt(dataValue / (targetLevelL + 12)) * 0.8 / 100
    }

    // 返回数据
    return {
      label,
      value,
      data
    }
  }
}