exports.install = function (Vue) {
  Vue.prototype.DataComparison = function (current, local) {
    // 数据对比上行数据和下沉数据
    let dataComparisonResult = {
      upstream: [],
      sink: []
    }

    Object.keys(current).filter(item => {
      Object.keys(local).filter(items => {
        if (item === items) {
          if (current[item] > local[items]) {
            // let label = item + 涨了
            // let value = current[item] - local[items]
            // dataComparisonResult.upstream.push({ label, value })
            dataComparisonResult.upstream.push(`${item}涨了:${current[item] - local[items]}`)
          }
          if (current[item] < local[items]) {
            // let label = item + 跌了
            // let value = local[items] - current[item]
            // dataComparisonResult.sink.push({ label, value })
            dataComparisonResult.sink.push(`${item}跌了:${local[items] - current[item]}`)
          }
        }
      })
    })
    // if (dataComparisonResult.upstream.length === 0) {
    //   dataComparisonResult.upstream = ''
    // }
    // if (dataComparisonResult.sink.length === 0) {
    //   dataComparisonResult.sink = ''
    // }
    return dataComparisonResult
  }
}