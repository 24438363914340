exports.install = function (Vue) {
  /**
   * 由于目前数据是异步的,所以需要定义一个时间作为同步的最大时间
   * @param {数据同步过期的时间} timer 
   * @returns 
   */
  Vue.prototype.dataSynchronizationTime = function (timer = 500) {
    return new Promise((resolve, reject) => {
      setTimeout(reject, timer, false)
    })
  }
}