exports.install = function (Vue) {
  Vue.prototype.magicenhancement = function (magicAttackData, mode, skill, appletVersion) {
    // 解构magicAttackData中的数据
    let { basicMagicAttack, magicattackpower, magichit, percentage } = magicAttackData
    // list数组表示要遍历的对象
    let list = []
    // 魔攻技能叠加
    let magics = 0
    // 魔命技能叠加
    let magichits = 0
    // 当前回合叠加的值
    let superposition = 0
    // 备份当前魔命数据
    let copymagichit = 0
    // 备份当前魔攻数据
    let copymagicattackpower = 0
    // 魔命叠加的数据值用数组进行保存
    let magichitsuperposition = []
    // 魔攻叠加的数据值用数组进行保存
    let magicattackpowersuperposition = []
    // 当前回合过期的的数据值
    let overdue = 0
    // 攻击力上限 ==> 小程序上限200w其他版本上限100w
    let upperLimit = 1000000
    if (appletVersion) {
      upperLimit = 2000000
    }
    // 记录保留第一次到达百万的数据值
    let record = true
    let upper = 0
    switch (skill) {
      // 魔攻只需计算当前叠加的数据值,并不用急着加上去,所以基础'1'先不用带上
      case 0:
      case 1:
        // 青鸟
        magics = 1.3
        magichits = 1.2
        break;
      case 2:
      case 3:
        // 21电羊
        magics = 1.25
        magichits = 1.25
        break;
      case 4:
      case 5:
        // 22电羊
        magics = 1.3
        magichits = 1.3
        break;
    }
    for (let i = 1; i <= 30; i++) {
      // 叠加和过期的数据不受回合影响
      // 超过3回合叠加数据开始过期
      if (i > 3) {
        // 魔命-魔攻数据过期
        magichit -= magichitsuperposition[i - 4]
        overdue = magicattackpowersuperposition[i - 4]
        // 当前回合过期数据值
        magicattackpower -= overdue
        // console.log(overdue);
      }
      switch (skill) {
        case 0:
        case 2:
        case 4:
          // 初始化魔攻叠加数据
          superposition = 0
          if (i % 2 !== 0) {
            // 备份当前魔命数据
            copymagichit = magichit
            // 魔命计算
            magichit = parseInt(magichit * magichits)
            copymagichit = magichit - copymagichit
            // 把叠加的魔命追加到数组中
            magichitsuperposition.push(copymagichit)
            // 备份当前魔攻
            copymagicattackpower = magicattackpower
            // 实际魔攻增长数据
            // 实际魔攻=面板魔攻*魔攻百分比加成+基础魔攻*百分比加成*魔命千分比加成
            magicattackpower = parseInt(magicattackpower * magics + basicMagicAttack * (percentage / 100 + 1) * copymagichit / 1000)

            // 最高100w
            if (magicattackpower >= upperLimit) {
              // 超过百万记为百万
              magicattackpower = upperLimit
            }
            // 叠加的魔攻数据
            superposition = magicattackpower - copymagicattackpower
            // 把魔攻增长数据追加到数组
            magicattackpowersuperposition.push(superposition)

            // 第一次到达百万时改变触发记录时刻
            if (magicattackpower >= upperLimit) {
              if (record) upper = i
              record = false

              // 以下混开数据保存仅为个人猜测
              if (i <= 3) {
                for (const key in magicattackpowersuperposition) {
                  magicattackpowersuperposition[key] = 0
                }
              } else {
                magicattackpowersuperposition[i - 1] = magicattackpowersuperposition[upper - 1]
              }
            }
          } else {
            // 把叠加的魔命追加到数组中
            magichitsuperposition.push(0)
            // 把魔攻增长数据追加到数组
            magicattackpowersuperposition.push(0)
          }
          if (mode) {
            list.push({
              round: `第【${i}】回合`,
              hit: `魔命值为:${magichit}`,
              data: `魔攻值为:${magicattackpower > upperLimit ? upperLimit : magicattackpower}`,
              overdue: `过期的魔攻值为:${overdue}`,
              superposition: `叠加的魔攻值为:${superposition}`,
              datas: magicattackpower > upperLimit ? upperLimit : magicattackpower
            })
          } else {
            list.push({
              round: `第【${i}】回合`,
              hit: `魔命值为:${magichit}`,
              data: `魔攻值为:${magicattackpower > upperLimit ? upperLimit : magicattackpower}`,
              datas: magicattackpower > upperLimit ? upperLimit : magicattackpower
            })
          }
          // 到达百万跳出当前循环
          // if (magicattackpower >= upperLimit) break
          break
        default:
          // 备份当前魔命数据
          copymagichit = magichit
          // 魔命计算
          magichit = parseInt(magichit * magichits)
          copymagichit = magichit - copymagichit

          // 把叠加的魔命追加到数组中
          magichitsuperposition.push(copymagichit)
          // 魔攻计算
          // 备份当前魔攻数据
          copymagicattackpower = magicattackpower
          // 实际魔攻增长数据
          magicattackpower = parseInt(magicattackpower * magics + basicMagicAttack * (percentage / 100 + 1) * copymagichit / 1000)

          // 第一次到达百万时改变触发记录时刻
          if (magicattackpower >= upperLimit) {
            if (record) upper = i
            record = false
          }
          // 最高100w
          if (magicattackpower >= upperLimit) {
            // 超过百万记为百万
            magicattackpower = upperLimit
          }
          // 叠加的魔攻数据
          superposition = magicattackpower - copymagicattackpower
          // magicattackpower = magicattackpower > upperLimit ? upperLimit : magicattackpower
          // 预防百万出错--将当前魔攻(加上叠加值)减去备份魔攻(未加叠加值)--防止到达百万叠加数据出现异常

          // 把魔攻增长数据追加到数组
          magicattackpowersuperposition.push(superposition)
          if (mode) {
            list.push({
              round: `第【${i}】回合`,
              hit: `魔命值为:${magichit}`,
              data: `魔攻值为:${magicattackpower > upperLimit ? upperLimit : magicattackpower}`,
              overdue: `过期的魔攻值为:${overdue}`,
              superposition: `叠加的魔攻值为:${superposition}`,
              datas: magicattackpower > upperLimit ? upperLimit : magicattackpower
            })
          } else {
            list.push({
              round: `第【${i}】回合`,
              hit: `魔命值为:${magichit}`,
              data: `魔攻值为:${magicattackpower > upperLimit ? upperLimit : magicattackpower}`,
              datas: magicattackpower > upperLimit ? upperLimit : magicattackpower
            })
          }
          // 到达百万跳出当前循环
          if (magicattackpower >= upperLimit) break
          break
      }
      // 到达百万跳出当前循环
      if (skill % 2 !== 0) {
        if (magicattackpower >= upperLimit) break
      }
      // else {
      //   if (i % 2 === 0) if (magicattackpower >= upperLimit) break
      // }
    }
    return {
      list,
      upper
    }
  }
}